import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import Button from 'src/components/Button';
import { BenefitsListContainer, BenefitRow } from './style';

interface BenefitsListProps {
  benefits: string[];
  removeBenefit: (index: number) => void;
  editBenefit: (benefit: number) => void;
  editingBenefit: number;
}

const BenefitsList: React.FC<BenefitsListProps> = ({
  benefits,
  removeBenefit,
  editBenefit,
  editingBenefit,
}) => {
  return (
    <BenefitsListContainer>
      {benefits.length ? (
        benefits.map((benefit, index) => {
          const beingEdited = editingBenefit === index;
          return (
            <BenefitRow key={benefit} editing={beingEdited}>
              <Button
                dangerFill
                type="button"
                small
                onClick={() => removeBenefit(index)}
              >
                <BsTrashFill />
              </Button>
              <Button
                primary={!beingEdited}
                outline={beingEdited}
                type="button"
                small
                onClick={() => editBenefit(index)}
              >
                <AiOutlineEdit />
              </Button>
              <span className="benefit">{benefit}</span>
            </BenefitRow>
          );
        })
      ) : (
        <span className="empty">
          Ainda não foram adicionados benefícios para este plano.
        </span>
      )}
    </BenefitsListContainer>
  );
};

export default BenefitsList;
