import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import CreateAndSearchContainer from 'src/layouts/AppLayout/components/CreateAndSearchContainer';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import Table from 'src/layouts/AppLayout/components/Table';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import Button from 'src/components/Button';
import TableAndTitleContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import { AuthorsContainer } from './style';
import home from 'src/assets/home.svg';
import Author from 'src/models/author';
import ActionButtonsContainer from 'src/layouts/AppLayout/components/ActionButtonsContainer';
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import makeQuestion from 'src/helpers/make-question';
import useModal from 'src/hooks/useModal';
import showErrorMessage from 'src/helpers/show-error-message';
import showSuccessMessage from 'src/helpers/show-success-message';
import SystemError from 'src/models/error';
import {
  deleteAuthor as deleteAuthorService,
  getAuthors as getAuthorsService,
  toggleAuthorActive as toggleAuthorActiveService,
  toggleContentHighlight as toggleContentHighlightService,
} from 'src/services/authors';
import clamp from 'clamp-js';
import useLoading from 'src/hooks/useLoading';
import CreateAndEditAuthors from './components/CreateAndEditAuthors';
import TableThumbnail from 'src/layouts/AppLayout/components/TableThumbnail';
import Switch from 'react-switch';

interface AuthorsProps {
  modalId?: string;
  onSelectAuthor?: (author: Author) => void;
}

const Authors: React.FC<AuthorsProps> = ({ modalId, onSelectAuthor }) => {
  const history = useHistory();
  const [authors, setAuthors] = useState([] as Author[]);
  const selectedAuthorsContainer = useRef<HTMLSpanElement>(null);
  const { setIsLoading } = useLoading();
  const { hideModal, showModal } = useModal();

  const getAuthors = useCallback(async () => {
    setIsLoading(true);

    const filters = {} as any;
    if (modalId) {
      filters.is_active = true;
    }

    const authors = await getAuthorsService(filters);
    setAuthors(authors);

    setIsLoading(false);
  }, [modalId, setIsLoading]);

  const createAuthor = () => {
    if (modalId) {
      showModal(
        'Criar autor',
        <CreateAndEditAuthors onUpdateOrSave={getAuthors} />
      );
    } else {
      history.push(`/customization/academic/authors/create-author`);
    }
  };

  const editAuthor = useCallback(
    (authorId: string) => {
      if (modalId) {
        showModal(
          'Editar autor',
          <CreateAndEditAuthors
            authorId={authorId}
            onUpdateOrSave={getAuthors}
          />
        );
      } else {
        history.push(`/customization/academic/authors/edit-author/${authorId}`);
      }
    },
    [history, getAuthors, modalId, showModal]
  );

  const removeAuthor = useCallback(
    async (authorId: string) => {
      const result = await makeQuestion('Deseja excluir este autor?');

      if (result.isConfirmed) {
        try {
          await deleteAuthorService(authorId);

          showSuccessMessage('Autor removido com sucesso');

          await getAuthors();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getAuthors]
  );

  const toggleAuthorActive = useCallback(
    async (authorId: string, isActive: boolean) => {
      const result = await makeQuestion(
        `Deseja ${isActive ? 'inativar' : 'ativar'} este autor?`
      );

      if (result.isConfirmed) {
        try {
          const shouldActive = !isActive;
          await toggleAuthorActiveService(authorId, shouldActive);

          showSuccessMessage(
            `Autor ${shouldActive ? 'ativado' : 'inativado'} com sucesso`
          );

          await getAuthors();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getAuthors]
  );

  const selectAuthor = useCallback(
    (author: Author) => {
      onSelectAuthor && onSelectAuthor(author);
      hideModal(modalId);
    },
    [hideModal, modalId, onSelectAuthor]
  );

  const toggleHighlight = useCallback(
    async (authorId: string, isHighlight: boolean) => {
      const result = await makeQuestion(
        `Tem certeza que deseja ${isHighlight ? 'remover o destaque d' : 'destacar '
        }este autor`
      );
      if (result.isConfirmed) {
        try {
          await toggleContentHighlightService(authorId);

          showSuccessMessage(
            `${isHighlight ? 'Destaque do autor removido' : 'Autor destacado'
            } com sucesso!`
          );

          await getAuthors();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getAuthors]
  );

  const authorsToShow = useMemo(() => {
    return (authors || []).map((author) => ({
      ...author,
      avatar: <TableThumbnail src={author?.images?.avatar} />,
      highlight: (
        <Switch
          onChange={() =>
            toggleHighlight(author.author_id, !!author.info.is_highlight)
          }
          checked={!!author.info.is_highlight}
        />
      ),
      actions: (
        <ActionButtonsContainer>
          {!modalId ? (
            <>
              <Button
                primary
                type="button"
                onClick={() => editAuthor(author.author_id)}
              >
                <AiOutlineEdit />
                <span>Editar</span>
              </Button>
              <Button
                warning
                type="button"
                onClick={() =>
                  toggleAuthorActive(author.author_id, author.is_active)
                }
              >
                {author.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}{' '}
                {author.is_active ? 'Inativar' : 'Ativar'}
              </Button>
              <Button
                dangerFill
                type="button"
                onClick={() => removeAuthor(author.author_id)}
              >
                <BsTrashFill />
                <span>Excluir</span>
              </Button>
            </>
          ) : (
            <>
              <Button type="button" onClick={() => selectAuthor(author)}>
                <AiOutlineCheck />
                <span>Selecionar</span>
              </Button>
            </>
          )}
        </ActionButtonsContainer>
      ),
    }));
  }, [
    authors,
    modalId,
    editAuthor,
    toggleAuthorActive,
    removeAuthor,
    toggleHighlight,
    selectAuthor,
  ]);

  useEffect(() => {
    getAuthors();
  }, [getAuthors]);

  useEffect(() => {
    if (selectedAuthorsContainer.current) {
      clamp(selectedAuthorsContainer.current, { clamp: 1 });
    }
  }, [selectedAuthorsContainer]);

  return (
    <AuthorsContainer isOnModal={!!modalId}>
      {!modalId ? (
        <TitleAndBreadcrumbContainer>
          <PageTitle>Autores</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/academic">Acadêmico</Link>,
              <span>Autores</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      ) : (
        <></>
      )}

      <CreateAndSearchContainer>
        <Button type="button" success onClick={createAuthor}>
          Criar novo autor
        </Button>
      </CreateAndSearchContainer>

      <TableAndTitleContainer>
        <PageTitle dark medium>
          Autores
        </PageTitle>
        <Table
          headerConfigs={[
            {
              label: 'Avatar',
              propName: 'avatar',
              attributes: { style: { width: '200px' } },
            },
            {
              label: 'Nome',
              propName: 'name',
              attributes: { style: { width: '240px' } },
            },
            {
              label: 'Descrição',
              propName: 'description',
            },
            {
              label: 'Destaque',
              propName: 'highlight',
            },
            {
              label: 'Ações',
              propName: 'actions',
              attributes: { style: { width: !modalId ? '340px' : '140px' } },
            },
          ]}
          items={authorsToShow}
        />
      </TableAndTitleContainer>
    </AuthorsContainer>
  );
};

export default Authors;
