import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import {
  ContentCardButtonsContainer,
  ContentCardContainer,
  CreateAndEditThemesContainer,
  SelectedContents,
} from './style';
import home from 'src/assets/home.svg';
import {
  CreationForm,
  CreationFormButtonGroup,
  CreationFormGroup,
  CreationFormImageGroup,
} from 'src/layouts/AppLayout/components/Forms';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import TitleAndThemeContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import ModalContext from 'src/contexts/ModalContext';
import showErrorMessage from 'src/helpers/show-error-message';
import Theme from 'src/models/theme';
import checkEmptyString from 'src/helpers/check-empty-string';
import SystemError from 'src/models/error';
import showSuccessMessage from 'src/helpers/show-success-message';
import {
  createTheme as createThemeService,
  updateTheme as updateThemeService,
  getTheme as getThemeService,
} from 'src/services/themes';
import useLoading from 'src/hooks/useLoading';
import Content from 'src/models/content';
import Contents from '../../../Contents';
import ThemeCreationContext from 'src/contexts/ThemeCreationContext';
import { BsTrashFill } from 'react-icons/bs';
import AppContext from 'src/contexts/AppContext';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

interface CreateAndEditThemesParams {
  themeId?: string;
}

interface ContentCardProps {
  content: Content;
  handlePositionChange: (content: Content, up: boolean) => void;
  isFirst: boolean;
  isLast: boolean;
}

const ContentCard: React.FC<ContentCardProps> = ({
  content,
  handlePositionChange,
  isFirst,
  isLast,
}) => {
  const { setSelectedContents } = useContext(ThemeCreationContext);

  const removeSelectedContent = () => {
    setSelectedContents((selectedContents) =>
      (selectedContents || []).filter(
        (c) => c.content_id !== content.content_id
      )
    );
  };

  return (
    <ContentCardContainer>
      <img
        src={content?.images?.thumbnail}
        alt="thumbnail"
        className="thumbnail"
      />
      <p className="name">{content.name}</p>
      <ContentCardButtonsContainer>
        <Button
          circle
          small
          dangerFill
          type="button"
          onClick={removeSelectedContent}
        >
          <BsTrashFill />
        </Button>
        {!isFirst && (
          <Button
            type="button"
            circle
            small
            primaryFill
            onClick={() => handlePositionChange(content, true)}
          >
            <AiOutlineUp />
          </Button>
        )}
        {!isLast && (
          <Button
            type="button"
            circle
            small
            primaryFill
            onClick={() => handlePositionChange(content, false)}
          >
            <AiOutlineDown />
          </Button>
        )}
      </ContentCardButtonsContainer>
    </ContentCardContainer>
  );
};

interface CreateAndEditThemesProps {
  modalId?: string;
  themeId?: string;
  onUpdateOrSave?: () => void;
}

const CreateAndEditThemes: React.FC<CreateAndEditThemesProps> = ({
  modalId,
  themeId: propsThemeId,
  onUpdateOrSave,
}) => {
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { showModal, hideModal } = useContext(ModalContext);
  const { themeId: paramsThemeId } = useParams<CreateAndEditThemesParams>();
  const [theme, setTheme] = useState({} as Theme);
  const { selectedContents, setSelectedContents } =
    useContext(ThemeCreationContext);
  const { selectedPartner } = useContext(AppContext);

  const themeId = useMemo(() => {
    return propsThemeId || paramsThemeId;
  }, [propsThemeId, paramsThemeId]);

  const handleChangeTheme = (propName: string, value: any) => {
    setTheme((theme) => {
      return { ...theme, [propName]: value };
    });
  };

  const validate = () => {
    if (checkEmptyString(theme.name)) {
      throw new Error('Informe um nome para o tema');
    }

    if (!theme?.children?.length) {
      throw new Error('Informe ao menos um conteúdo para o tema');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      theme.children = (selectedContents || []).map((c, index) => ({
        child_id: c.content_id,
        position: index + 1,
      }));

      validate();

      if (selectedPartner?.segmentation_item_id) {
        theme.segmentation_items = [
          {
            segmentation_item_id:
              selectedPartner.segmentation_item_id ||
              process.env.REACT_APP_MANOLE_UUID ||
              '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
          },
        ];
      }

      if (isEditing) {
        await updateThemeService(themeId!, theme);
        showSuccessMessage('Tema editado com sucesso!');
      } else {
        await createThemeService(theme);
        showSuccessMessage('Tema cadastrado com sucesso!');
      }

      goToThemes();
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  };

  const getTheme = useCallback(async () => {
    if (themeId) {
      setIsLoading(true);

      const theme = await getThemeService(themeId);
      setSelectedContents(theme.children as Content[]);
      setTheme(theme);

      setIsLoading(false);
    }
  }, [themeId, setIsLoading, setSelectedContents]);

  const goToThemes = () => {
    if (modalId) {
      onUpdateOrSave && onUpdateOrSave();
      hideModal(modalId);
    } else {
      history.push('/customization/academic/themes');
    }
  };

  const selectContents = () => {
    showModal('Selecionar conteúdos', <Contents />);
  };

  const isEditing = useMemo(() => {
    return !!themeId;
  }, [themeId]);

  const isFirstContent = useCallback(
    (content: Content) => {
      const indexOfContent = selectedContents.indexOf(content);
      return indexOfContent === 0;
    },
    [selectedContents]
  );

  const isLastContent = useCallback(
    (content: Content) => {
      const indexOfContent = selectedContents.indexOf(content);
      return indexOfContent === selectedContents.length - 1;
    },
    [selectedContents]
  );

  const handleMoveUp = (content: Content) => {
    const indexOfContent = selectedContents.indexOf(content);

    if (isFirstContent(content)) {
      return;
    }

    const newContent = selectedContents.splice(indexOfContent, 1);
    selectedContents.splice(indexOfContent - 1, 0, newContent[0]);
  };

  const handleMoveDown = (content: Content) => {
    const indexOfContent = selectedContents.indexOf(content);

    if (isLastContent(content)) {
      return;
    }

    const newContent = selectedContents.splice(indexOfContent, 1);
    selectedContents.splice(indexOfContent + 1, 0, newContent[0]);
  };

  const handleChangePosition = (content: Content, up: boolean) => {
    if (up) {
      handleMoveUp(content);
    } else {
      handleMoveDown(content);
    }
    setSelectedContents([...selectedContents]);
  };

  useEffect(() => {
    if (!isEditing) {
      setSelectedContents([]);
    }
  }, [isEditing, setSelectedContents]);

  useEffect(() => {
    getTheme();
  }, [getTheme]);

  return (
    <CreateAndEditThemesContainer isOnModal={!!modalId}>
      {!modalId ? (
        <TitleAndBreadcrumbContainer>
          <PageTitle>{isEditing ? 'Editar' : 'Criar'} Tema</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/academic">Acadêmico</Link>,
              <Link to="/customization/academic/themes">Temas</Link>,
              <span>{isEditing ? 'Editar' : 'Criar'} Temas</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      ) : (
        <></>
      )}

      <TitleAndThemeContainer>
        <PageTitle dark medium>
          {isEditing ? 'Editar' : 'Criar'} Tema
        </PageTitle>

        <CreationForm onSubmit={handleSubmit}>
          <CreationFormGroup>
            <label htmlFor="name" className="required">
              Nome
            </label>
            <Input
              id="name"
              placeholder="Nome"
              value={theme.name}
              onChange={(e) => handleChangeTheme('name', e.target.value)}
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="contents">Contéudos</label>

            <CreationFormImageGroup>
              <Button type="button" onClick={selectContents}>
                Selecionar vídeos
              </Button>

              {selectedContents?.length ? (
                <SelectedContents>
                  {selectedContents.map((content) => (
                    <ContentCard
                      key={content.content_id}
                      content={content}
                      handlePositionChange={handleChangePosition}
                      isFirst={isFirstContent(content)}
                      isLast={isLastContent(content)}
                    />
                  ))}
                </SelectedContents>
              ) : (
                <></>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormButtonGroup>
            <Button danger type="button" onClick={goToThemes}>
              Cancelar
            </Button>
            <Button primary>Salvar tema</Button>
          </CreationFormButtonGroup>
        </CreationForm>
      </TitleAndThemeContainer>
    </CreateAndEditThemesContainer>
  );
};

export default CreateAndEditThemes;
