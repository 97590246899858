import FeatureList from 'src/layouts/AppLayout/components/FeatureList';
import styled from 'styled-components';

const AcademicContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const AcademicFeatureList = styled(FeatureList)``;

export { AcademicContainer, AcademicFeatureList };
