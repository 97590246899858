import styled from 'styled-components';

const CreationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  .flex {
    display: flex;
    gap: 24px;
  }
`;

const CreationFormGroup = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;

  label {
    font-weight: 600;
    color: var(--text-gray);
    font-size: 0.8rem;

    &.required {
      ::after {
        content: ' *';
        color: red;
        font-size: 0.7rem;
      }
    }
  }
`;

const CreationFormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  margin-top: 8px;
`;

const CreationFormImageGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 16px;
  border: dashed 1px var(--border-gray);
  border-radius: 4px;

  button {
    flex: 1;
    width: 100%;
    padding: 16px 0;

    :hover {
      box-shadow: 0 0 3px 1px var(--primary);
    }
  }
`;

const CreationFormSwitch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  white-space: nowrap;

  label {
    font-weight: 600;
    color: var(--text-gray);
    font-size: 0.8rem;

    &.required {
      ::after {
        content: ' *';
        color: red;
        font-size: 0.7rem;
      }
    }
  }
`;

export {
  CreationForm,
  CreationFormGroup,
  CreationFormButtonGroup,
  CreationFormImageGroup,
  CreationFormSwitch,
};
