import httpClient from 'src/http-client'
import Partner from 'src/models/partner'
import User from 'src/models/user'
import { Mask } from 'src/helpers/Mask'

const getPartners = async (filters?: any) => {
  const { data } = await httpClient.get<Partner[]>(`/partners`, {
    params: filters,
  })
  return data
}

const getPartner = async (partnerId: string) => {
  const { data } = await httpClient.get<Partner>(`/partners/${partnerId}`, {
    params: { relations: ['children'] },
  })
  return data
}

const createPartner = async (newPartner: Partial<Partner>) => {
  const { data } = await httpClient.post(`/partners`, { ...newPartner })
  return data
}

const updatePartner = async (partnerId: string, newPartner: Partial<Partner>) => {
  delete newPartner.partner_id
  delete newPartner.created_at
  delete newPartner.updated_at
  delete newPartner.segmentation_item_id

  const { data } = await httpClient.put(`/partners/${partnerId}`, {
    ...newPartner,
  })

  return data
}

const deletePartner = async (partnerId: string) => {
  await httpClient.delete(`/partners/${partnerId}`)
}

const togglePartnerActive = async (partnerId: string, shouldActive: boolean) => {
  const { data } = await httpClient.patch(`/partners/${partnerId}/active`, {
    is_active: shouldActive,
  })
  return data
}

export {
  getPartners,
  getPartner,
  createPartner,
  updatePartner,
  deletePartner,
  togglePartnerActive,
}

export const linkUserToPartnerService = async (partner_id: string, cpf: string) => {
  const cpfOnlyNumbers = Mask.number(cpf)

  const { data } = await httpClient.post<User>(`/partners/user/${partner_id}`, {
    cpf: cpfOnlyNumbers
  })

  return data
}

export const unlinkUserFromPartnerService = async (partner_id: string, user_id: string) => {
  await httpClient.delete(`/partners/users/${partner_id}`, {
    data: {
      user_id,
    }
  })
}

export const inviteUserByEmailService = async (partner_id: string, name: string, email: string) => {
  const { data } = await httpClient.post<User>(`/partners/${partner_id}/invite`, {
    name,
    email,
  })
  return data
}
