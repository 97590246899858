import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import clamp from 'clamp-js';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import Button from 'src/components/Button';
import CreateAndEditTag from './components/createAndEditTag';
import { SelectedTags, TagsContainer } from './style';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import CreateAndSearchContainer from 'src/layouts/AppLayout/components/CreateAndSearchContainer';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import Table from 'src/layouts/AppLayout/components/Table';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import TableAndTitleContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import ActionButtonsContainer from 'src/layouts/AppLayout/components/ActionButtonsContainer';
import TableThumbnail from 'src/layouts/AppLayout/components/TableThumbnail';
import useModal from 'src/hooks/useModal';
import useLoading from 'src/hooks/useLoading';
import SystemError from 'src/models/error';
import {
  deleteTagService,
  ITag,
  listTagsService,
  updateTagStatusService,
} from 'src/services/tags';
import makeQuestion from 'src/helpers/make-question';
import showErrorMessage from 'src/helpers/show-error-message';
import showSuccessMessage from 'src/helpers/show-success-message';
import home from 'src/assets/home.svg';


interface TagsProps {
  modalId?: string;
  onSelectedTags?: (tags: ITag[]) => void;
  alreadySelectedTags?: ITag[];
}

type SelectedTagsHash = Record<string, ITag>;

const Tags: React.FC<TagsProps> = ({
  modalId,
  onSelectedTags,
  alreadySelectedTags,
}) => {
  const history = useHistory();
  const [tags, setTags] = useState([] as ITag[]);

  const [selectedTags, setSelectedTags] = useState<SelectedTagsHash>({});

  const selectedTagsContainer = useRef<HTMLSpanElement>(null);
  const { setIsLoading } = useLoading();
  const { hideModal, showModal } = useModal();

  const getTags = useCallback(async () => {
    setIsLoading(true);

    const { data: tags } = await listTagsService();
    setTags(tags);

    setIsLoading(false);
  }, [setIsLoading]);

  const createTag = () => {
    if (modalId) {
      showModal('Criar tag', <CreateAndEditTag onUpdateOrSave={getTags} />);
    } else {
      history.push(`/customization/academic/tags/create-tag`);
    }
  };

  const editTag = useCallback(
    (tagId: string) => {
      if (modalId) {
        showModal(
          'Editar tag',
          <CreateAndEditTag tagId={tagId} onUpdateOrSave={getTags} />
        );
      } else {
        history.push(`/customization/academic/tags/edit-tag/${tagId}`);
      }
    },
    [history, getTags, modalId, showModal]
  );

  const removeTag = useCallback(
    async (tagId: string) => {
      const result = await makeQuestion('Deseja excluir esta tag?');

      if (result.isConfirmed) {
        try {
          await deleteTagService(tagId);

          showSuccessMessage('Tag removida com sucesso');

          await getTags();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getTags]
  );

  const toggleTagActive = useCallback(
    async (tagId: string, isActive: boolean) => {
      const result = await makeQuestion(
        `Deseja ${isActive ? 'inativar' : 'ativar'} esta tag?`
      );

      if (result.isConfirmed) {
        try {
          const shouldActive = !isActive;
          await updateTagStatusService(tagId, shouldActive);

          showSuccessMessage(
            `Tag ${shouldActive ? 'ativada' : 'inativada'} com sucesso`
          );

          await getTags();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getTags]
  );

  const isSelectedTag = useCallback(
    (tag: ITag) => {
      return selectedTags[tag.tag_id!];
    },
    [selectedTags]
  );

  const selectTag = useCallback(
    (tag: ITag) => {
      setSelectedTags(
        Object.assign({ ...selectedTags }, { [tag.tag_id!]: tag })
      );
    },
    [selectedTags]
  );

  const unselectTag = useCallback(
    (tag: ITag) => {
      const selectedTagsCopy = {
        ...selectedTags
      }

      delete selectedTagsCopy[tag.tag_id!];

      setSelectedTags(
        Object.assign({ ...selectedTagsCopy })
      );
    },
    [selectedTags]
  );

  const applySelectedTags = () => {
    onSelectedTags && onSelectedTags(Object.values(selectedTags));
    hideModal(modalId);
  };

  const tagsToShow = useMemo(() => {
    return (tags || []).map((tag) => ({
      ...tag,
      thumbnail: tag?.images?.thumbnail ? (
        <TableThumbnail src={tag?.images?.thumbnail} />
      ) : (
        <></>
      ),
      rowColor: !modalId
        ? 'auto'
        : isSelectedTag(tag)
          ? 'var(--primary-40)'
          : 'transparent',
      actions: (
        <ActionButtonsContainer>
          {!modalId ? (
            <>
              <Button
                primary
                type="button"
                onClick={() => editTag(tag.tag_id!)}
              >
                <AiOutlineEdit />
                <span>Editar</span>
              </Button>
              <Button
                warning
                type="button"
                onClick={() => toggleTagActive(tag.tag_id!, tag.is_active)}
              >
                {tag.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}{' '}
                {tag.is_active ? 'Inativar' : 'Ativar'}
              </Button>
              <Button
                dangerFill
                type="button"
                onClick={() => removeTag(tag.tag_id!)}
              >
                <BsTrashFill />
                <span>Excluir</span>
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                table
                onClick={() =>
                  isSelectedTag(tag) ? unselectTag(tag) : selectTag(tag)
                }
              >
                {isSelectedTag(tag) ? (
                  <>
                    <AiOutlineMinus />
                    <span>Remover</span>
                  </>
                ) : (
                  <>
                    <AiOutlinePlus />
                    <span>Adicionar</span>
                  </>
                )}
              </Button>
            </>
          )}
        </ActionButtonsContainer>
      ),
    }));
  }, [
    editTag,
    isSelectedTag,
    modalId,
    removeTag,
    selectTag,
    tags,
    toggleTagActive,
    unselectTag,
  ]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  useEffect(() => {
    if (selectedTagsContainer.current) {
      clamp(selectedTagsContainer.current, { clamp: 1 });
    }
  }, [selectedTagsContainer]);

  useEffect(() => {
    if (alreadySelectedTags?.length) {
      const selectedTagsHash: SelectedTagsHash = {};
      setSelectedTags(
        alreadySelectedTags.reduce(
          (hash, tag) => Object.assign(hash, { [tag.tag_id!]: tag }),
          selectedTagsHash
        )
      );
    }
  }, [alreadySelectedTags]);

  return (
    <TagsContainer isOnModal={!!modalId}>
      {!modalId ? (
        <TitleAndBreadcrumbContainer>
          <PageTitle>Tags</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/academic">Acadêmico</Link>,
              <span>Tags</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      ) : (
        <></>
      )}

      <CreateAndSearchContainer>
        <Button type="button" success onClick={createTag}>
          Criar novo tag
        </Button>
      </CreateAndSearchContainer>

      {modalId ? (
        <SelectedTags>
          {selectedTags?.length ? (
            <>
              <strong>Tags selecionados:</strong>
              <span ref={selectedTagsContainer} className="selected-tags">
                {(Object.values(selectedTags) || []).map((c) => c.title).join(', ')}
              </span>
            </>
          ) : (
            <span></span>
          )}
          <Button primary type="button" onClick={applySelectedTags}>
            Aplicar seleção
          </Button>
        </SelectedTags>
      ) : (
        <></>
      )}

      <TableAndTitleContainer>
        <PageTitle dark medium>
          Tags
        </PageTitle>
        <Table
          headerConfigs={[
            {
              label: 'Imagem',
              propName: 'thumbnail',
            },
            {
              label: 'Nome',
              propName: 'title',
            },
            {
              label: 'Descrição',
              propName: 'description',
            },
            {
              label: 'Ações',
              propName: 'actions',
              attributes: { style: { width: '340px' } },
            },
          ]}
          items={tagsToShow}
        />
      </TableAndTitleContainer>
    </TagsContainer>
  );
};

export default Tags;
