import FeatureList from 'src/layouts/AppLayout/components/FeatureList';
import styled from 'styled-components';

const SearchResultsContainer = styled.div`
  flex: 1;
  width: 100%;

  .no-results {
    color: #fff;
  }
`;

const ResultsList = styled(FeatureList)``;

export { SearchResultsContainer, ResultsList };
