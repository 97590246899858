import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ReactSwitch from 'react-switch'
import clamp from 'clamp-js'
import { BsTrashFill } from 'react-icons/bs'
import { AiOutlineEdit } from 'react-icons/ai';
import Input from 'src/components/Input'
import Button from 'src/components/Button'
import { Stack } from 'src/components/Stack'
import { CreationFormGroup } from 'src/components/Select/style'
import Table from 'src/layouts/AppLayout/components/Table'
import PageTitle from 'src/layouts/AppLayout/components/PageTitle'
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb'
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer'
import TableAndTitleContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer'
import { CreationForm } from 'src/layouts/AppLayout/components/Forms'
import { ContainerBtnAddUser, ContainerInfo, UsersContainer } from './style'
import {
  deleteUserService,
  getUsers as getUsersService,
  toggleUserAdmin as toggleUserAdminService,
} from 'src/services/users'
import {
  inviteUserByEmailService,
  linkUserToPartnerService,
  unlinkUserFromPartnerService
} from 'src/services/partners'
import useApp from 'src/hooks/useApp'
import useLoading from 'src/hooks/useLoading'
import User from 'src/models/user'
import SystemError from 'src/models/error'
import { Mask } from 'src/helpers/Mask'
import makeQuestion from 'src/helpers/make-question'
import showErrorMessage from 'src/helpers/show-error-message'
import showSuccessMessage from 'src/helpers/show-success-message'
import home from 'src/assets/home.svg'

const Users: React.FC = () => {
  const selectedUsersContainer = useRef<HTMLSpanElement>(null)

  const history = useHistory();
  const { selectedPartner } = useApp()
  const { setIsLoading } = useLoading()

  const [users, setUsers] = useState([] as User[])
  const [inviteCreateUser, setInviteCreateUser] = useState(false)
  const [inviteUser, setInviteUser] = useState({
    cpf: "",
    name: "",
    email: "",
  })

  const getUsers = useCallback(async () => {
    try {
      setIsLoading(true)

      const partner_id = selectedPartner?.partner_id

      const users = await getUsersService({ partner_id })
      setUsers(users)

      setIsLoading(false)
    } catch (error) {
      showErrorMessage(error as SystemError)
    }
  }, [setIsLoading, selectedPartner])

  useEffect(() => { getUsers() }, [getUsers])

  useEffect(() => {
    if (selectedUsersContainer.current) {
      clamp(selectedUsersContainer.current, { clamp: 1 })
      console.log('selectedUsersContainer.current: ', selectedUsersContainer.current)
    }
  }, [selectedUsersContainer])

  const handleForm = (prop: string, value: string) => {
    setInviteUser(currentValue => ({
      ...currentValue,
      [prop]: value
    }))
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!inviteCreateUser) linkUserToPartner()
    else inviteUserByEmail()
  }

  const handleEditUser = useCallback(
    (userId: string) => {
      history.push(`/users/edit/${userId}`);
    },
    [history]
  )

  const toggleUserAdmin = useCallback(
    async (userId: string, isAdmin: boolean) => {
      const result = await makeQuestion(
        `Deseja ${isAdmin ? 'tomar' : 'dar'
        } os privilégios de administrador para este usuário?`
      )

      if (result.isConfirmed) {
        try {
          const shouldAdmin = !isAdmin
          await toggleUserAdminService(userId)

          showSuccessMessage(
            `Privilégios ${shouldAdmin ? 'dados' : 'tomados'} com sucesso`
          )

          await getUsers()
        } catch (error) {
          showErrorMessage(error as SystemError)
        }
      }
    },
    [getUsers]
  )

  const linkUserToPartner = useCallback(
    async () => {
      try {
        const partner_id = selectedPartner?.partner_id

        if (!partner_id) {
          const error = new Error('Erro ao identificar o parceiro.')
          showErrorMessage(error as SystemError)
          return
        }

        const { cpf } = inviteUser

        await linkUserToPartnerService(partner_id, cpf)

        showSuccessMessage('Usuário vinculado!')

        setInviteUser({
          cpf: "",
          name: "",
          email: "",
        })

        await getUsers()
      } catch (error) {
        setInviteCreateUser(true)
      }
    },
    [selectedPartner, inviteUser, getUsers]
  )

  const unlinkUserFromPartner = useCallback(
    async (userId: string) => {
      const partner_id = selectedPartner?.partner_id

      if (!partner_id) throw new Error('Erro ao identificar o parceiro.')

      const result = await makeQuestion('Deseja desvincular o usuário desse parceiro?')

      if (!result.isConfirmed) return

      try {
        await unlinkUserFromPartnerService(partner_id, userId)

        showSuccessMessage('Usuário desvinculado!')

        await getUsers()
      } catch (error) {
        showErrorMessage(error as SystemError)
      }
    },
    [selectedPartner, getUsers]
  )

  const inviteUserByEmail = useCallback(
    async () => {
      try {
        const partner_id = selectedPartner?.partner_id

        if (!partner_id) throw new Error('Erro ao identificar o parceiro.')

        const { name, email } = inviteUser

        await inviteUserByEmailService(partner_id, name, email)

        showSuccessMessage('Usuário convidado!')

        setInviteUser({
          cpf: "",
          name: "",
          email: "",
        })

        await getUsers()
      } catch (error) {
        showErrorMessage(error as SystemError)
      } finally {
        setInviteCreateUser(false)
      }
    },
    [selectedPartner, inviteUser, getUsers]
  )

  const deleteUser = useCallback(
    async (userId: string) => {
      const result = await makeQuestion('Deseja realmente excluir esse usuário?')

      if (!result.isConfirmed) return

      try {
        await deleteUserService(userId)

        showSuccessMessage('Usuário excluido!')

        await getUsers()
      } catch (error) {
        showErrorMessage(error as SystemError)
      }
    },
    [getUsers]
  )

  return (
    <UsersContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Usuários</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Outros</strong>,
            <span>Usuários</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <ContainerBtnAddUser>
        <Button onClick={() => history.push('users/create')} success>
          Criar novo usuário
        </Button>
      </ContainerBtnAddUser>

      <TableAndTitleContainer padding={selectedPartner ? '16px 24px 0' : '16px 24px'}>
        <PageTitle dark medium>
          Usuários
        </PageTitle>

        {selectedPartner && (
          <CreationForm onSubmit={handleSubmit}>
            <ContainerInfo>
              {!inviteCreateUser
                ? <p>Vincular um usuário pelo CPF:</p>
                : (
                  <>
                    <p>O usuário não possui cadastro.</p>
                    <p>Convide-o a criar uma conta na Manole Play para acessar o conteúdo do parceiro.</p>
                  </>
                )
              }
            </ContainerInfo>

            <Stack gap="24px" alignItems="flex-end" inline>
              {!inviteCreateUser
                ? (
                  <CreationFormGroup style={{ width: '300px' }}>
                    <label htmlFor="cpf" className="required">
                      CPF
                    </label>
                    <Input
                      id="cpf"
                      placeholder="CPF"
                      value={inviteUser.cpf}
                      onChange={(e) => handleForm('cpf', Mask.cpf(e.target.value))}
                    />
                  </CreationFormGroup>
                )
                : (
                  <>
                    <CreationFormGroup style={{ width: '300px' }}>
                      <label htmlFor="name" className="required">
                        Nome
                      </label>
                      <Input
                        id="name"
                        placeholder="Nome"
                        value={inviteUser.name}
                        onChange={(e) => handleForm('name', e.target.value)}
                      />
                    </CreationFormGroup>

                    <CreationFormGroup style={{ width: '300px' }}>
                      <label htmlFor="email" className="required">
                        Email
                      </label>
                      <Input
                        id="email"
                        placeholder="Email"
                        value={inviteUser.email}
                        onChange={(e) => handleForm('email', e.target.value)}
                      />
                    </CreationFormGroup>
                  </>
                )
              }

              <Button type="submit" success>
                {!inviteCreateUser ? 'Vincular' : 'Convidar'}
              </Button>
            </Stack>
          </CreationForm>
        )}

        <Table
          itemsPerPage={20}
          headerConfigs={[
            {
              label: 'Nome',
              propName: 'name',
              attributes: { style: { width: '240px' } },
            },
            {
              label: 'Email',
              propName: 'email',
            },
            {
              label: 'Admin',
              propName: 'admin',
              attributes: { style: { width: '100px' } },
            },
            {
              label: 'Ações',
              propName: 'actions',
              attributes: { style: { width: '160px' } },
            },
          ]}
          items={
            useMemo(() => {
              return (users || []).map((user) => ({
                ...user,
                admin: (
                  <ReactSwitch
                    checked={user.is_admin}
                    onChange={() => toggleUserAdmin(user.user_id, user.is_admin)}
                  />
                ),
                actions: (
                  <Stack gap='4px' inline>
                    {selectedPartner && (
                      <Button
                        type="button"
                        onClick={() => unlinkUserFromPartner(user.user_id)}
                        primary
                      >
                        <span>Desvincular</span>
                      </Button>
                    )}

                    <Button
                      type="button"
                      onClick={() => handleEditUser(user.user_id)}
                      warning
                    >
                      <AiOutlineEdit />
                      <span>Editar</span>
                    </Button>

                    <Button
                      type="button"
                      onClick={() => deleteUser(user.user_id)}
                      dangerFill
                    >
                      <BsTrashFill />
                      <span>Excluir</span>
                    </Button>
                  </Stack>
                ),
              }))
            }, [users, selectedPartner, toggleUserAdmin, unlinkUserFromPartner, handleEditUser, deleteUser])
          }
        />
      </TableAndTitleContainer>
    </UsersContainer>
  )
}

export default Users
