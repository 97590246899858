import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import {
  CreationForm,
  CreationFormButtonGroup,
  CreationFormGroup,
} from 'src/layouts/AppLayout/components/Forms';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import TitleAndPlanContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import TextArea from 'src/layouts/AppLayout/components/TextArea';
import BenefitsList from './components/BenefitsList';
import Checkbox from 'src/components/Checkbox';
import { Select } from 'src/components/Select';
import {
  CreateAndEditPlansContainer,
  BenefitsFormGroup,
  PriceFormGroup,
} from './style';
import {
  createPlan as createPlanService,
  updatePlan as updatePlanService,
  getPlan as getPlanService,
  getPlansVersionsServices,
} from 'src/services/plans';
import useLoading from 'src/hooks/useLoading';
import Plan, { IPlanVersion } from 'src/models/plan';
import SystemError from 'src/models/error';
import showErrorMessage from 'src/helpers/show-error-message';
import checkEmptyString from 'src/helpers/check-empty-string';
import showSuccessMessage from 'src/helpers/show-success-message';
import home from 'src/assets/home.svg';

interface CreateAndEditPlansParams {
  planId?: string;
}

interface CreateAndEditPlansProps {
  modalId?: string;
  planId?: string;
}

const CreateAndEditPlans: React.FC<CreateAndEditPlansProps> = ({ modalId, planId: propsPlanId }) => {
  const history = useHistory();
  const goToPlans = () => history.push('/customization/plans')

  const { setIsLoading } = useLoading();
  const { planId: paramsPlanId } = useParams<CreateAndEditPlansParams>();

  const [plansVersions, setPlansVersions] = useState<IPlanVersion[]>()

  const getPlansVersions = async () => {
    const _plansVersions = await getPlansVersionsServices()
    setPlansVersions(_plansVersions)
  }

  useEffect(() => { getPlansVersions() }, [])

  const [plan, setPlan] = useState<Partial<Plan>>({
    name: "",
    description: "",
    manole_version_id: 0,
    full_price: 0,
    price: 0,
    benefits: [],
    info: {
      is_active: true,
      plan_days_quantity: "",
      url_plan: "",
    }
  })
  const [priceString, setPriceString] = useState<string>('0,00');
  const [fullPriceString, setFullPriceString] = useState<string>('0,00');
  const [benefits, setBenefits] = useState<string[]>([]);
  const [benefitsInput, setBenefitsInput] = useState<string>('');
  const [editingBenefit, setEditingBenefit] = useState<number>(-1);
  const [benefitFocused, setBenefitFocused] = useState<boolean>(false);
  const [discount, setDiscount] = useState<boolean>(false);

  const planId = useMemo(() => {
    return propsPlanId || paramsPlanId;
  }, [propsPlanId, paramsPlanId]);

  const isEditing = useMemo((): boolean => {
    return !!planId;
  }, [planId]);

  const updateBenefitsInput = useCallback(
    (index: number): void => {
      if (index < 0) {
        setBenefitsInput('');
      } else {
        setBenefitsInput(benefits[editingBenefit]);
      }
    },
    [editingBenefit, benefits]
  );

  useEffect(() => {
    updateBenefitsInput(editingBenefit);
  }, [editingBenefit, updateBenefitsInput]);

  const getPlan = useCallback(async () => {
    if (planId) {
      setIsLoading(true);

      try {
        const plan = await getPlanService(planId);

        setPlan(plan);
        setPriceString(`${plan.price}`.replace(/\./g, ','));

        if (Number(plan.full_price)) {
          setDiscount(true);
          setFullPriceString(`${plan.full_price}`.replace(/\./g, ','));
        }

        if (plan.benefits.length) {
          setBenefits(plan.benefits);
        }
      } catch (error) {
        showErrorMessage(error as SystemError);
      } finally {
        setIsLoading(false);
      }
    }
  }, [planId, setIsLoading]);

  useEffect(() => { getPlan(); }, [getPlan]);

  const handleSelectName = (event: any) => {
    const idPlanSelected = event.target.value

    const planSelected = plansVersions?.find(planV => String(planV.id) === idPlanSelected)

    const name = planSelected?.name || ''
    const manole_version_id = planSelected?.id || undefined
    const plan_days_quantity = planSelected?.qtd_days || ''

    setPlan((oldPlan): Partial<Plan> => ({
      ...oldPlan,
      name,
      manole_version_id,
      info: {
        is_active: !!oldPlan.info?.is_active,
        url_plan: oldPlan.info?.url_plan || '',
        plan_days_quantity,
      }
    }))
  }

  const handleChangePlan = (propName: string, value: any) => {
    setPlan((plan: any): any => {
      if (propName === 'url_plan') {
        return { ...plan, info: { ...plan.info, [propName]: value } }
      }

      return { ...plan, [propName]: value }
    });
  };

  const handleChangePrice = (event: FormEvent<HTMLInputElement>, mainPrice: boolean): void => {
    let price: string = event.currentTarget.value.replace(/\D/g, '');
    if (price[0] === '0') {
      price = price.replace(/^0+/, '');
    }
    if (price.length < 3) {
      price = price.padStart(3, '0');
    }
    if (mainPrice) {
      setPriceString(price.replace(/(\d)(\d{2})$/, '$1,$2'));
    } else {
      setFullPriceString(price.replace(/(\d)(\d{2})$/, '$1,$2'));
    }
  };

  const handleSubmitBenefit = (event: FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const benefit = benefitsInput.trim();
    if (editingBenefit < 0) {
      setBenefits((prev) => [...prev, benefit]);
    } else {
      let updatedBenefits = benefits;
      updatedBenefits[editingBenefit] = benefit;
      setBenefits(updatedBenefits);
      setEditingBenefit(-1);
    }
  };

  const handleEditBenefit = (index: number): void => {
    if (editingBenefit !== index) {
      setEditingBenefit(index);
    } else {
      setEditingBenefit(-1);
    }
  };

  const handleRemoveBenefit = (index: number): void => {
    let newArray = [...benefits];
    newArray.splice(index, 1);
    setBenefits(newArray);
    if (index === editingBenefit) {
      setEditingBenefit(-1);
    }
  };

  const convertPrice = (price: string): number => Number(price.replace(/,/, '.'));

  const validate = () => {
    if (checkEmptyString(plan.name)) {
      throw new Error('Informe um nome para o plano');
    }
    if (checkEmptyString(plan.info?.url_plan)) {
      throw new Error('Informe uma URL para o plano');
    }
    if (checkEmptyString(plan.description)) {
      throw new Error('Informe uma descrição para o plano');
    }
    if (discount && convertPrice(priceString) >= convertPrice(fullPriceString)) {
      throw new Error('O preço com desconto deve ser menor que o preço sem desconto');
    }
    if (!benefits.length) {
      throw new Error('Informe pelo menos um benefício para o plano');
    }
  };

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    try {
      validate();

      const newPlan = {
        ...plan,
        price: convertPrice(priceString),
        full_price: discount ? convertPrice(fullPriceString) : 0,
        benefits,
      };

      if (isEditing) {
        await updatePlanService(planId!, newPlan);
        showSuccessMessage('Plano editado com sucesso!');
      } else {
        await createPlanService(newPlan);
        showSuccessMessage('Plano cadastrado com sucesso!');
      }

      goToPlans();
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  };

  return (
    <CreateAndEditPlansContainer isOnModal={!!modalId}>
      {!modalId && (
        <TitleAndBreadcrumbContainer>
          <PageTitle>{isEditing ? 'Editar' : 'Criar'} Plano</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/plans">Planos</Link>,
              <span>{isEditing ? 'Editar' : 'Criar'} Plano</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      )}

      <TitleAndPlanContainer>
        <PageTitle dark medium>
          {isEditing ? 'Editar' : 'Criar'} Plano
        </PageTitle>

        <CreationForm onSubmit={handleSubmit}>
          <div className="flex">
            <Select
              id="name"
              label="Nome"
              value={plan.manole_version_id}
              options={plansVersions?.map(planV => ({ value: String(planV.id), name: planV.name }))}
              onChange={handleSelectName}
              flexGrow={1}
              required
            />

            <CreationFormGroup width="200px">
              <label htmlFor="plan_days_quantity">
                Quantidade de dias
              </label>
              <Input
                id="plan_days_quantity"
                placeholder="Quantidade de dias"
                value={plan.info?.plan_days_quantity}
                disabled
              />
            </CreationFormGroup>
          </div>

          <CreationFormGroup>
            <label htmlFor="url_plan" className="required">
              Url do plano
            </label>
            <Input
              id="url_plan"
              placeholder="Url do plano"
              value={plan.info?.url_plan}
              onChange={(e) => handleChangePlan('url_plan', e.target.value)}
              autoComplete="off"
              required
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="description" className="required">
              Descrição
            </label>
            <TextArea
              id="description"
              placeholder="Descrição"
              value={plan.description}
              onChange={(e) => handleChangePlan('description', e.target.value)}
              required
            />
          </CreationFormGroup>

          <PriceFormGroup isCheckbox>
            <Checkbox id="discount" checked={discount} setChecked={setDiscount} />
            <label htmlFor="discount">Desconto</label>
          </PriceFormGroup>

          <PriceFormGroup>
            <CreationFormGroup>
              <label htmlFor="price" className="required">
                {`Preço${discount ? ' com desconto' : ''}`}
              </label>
              <Input
                id="price"
                placeholder="R$ 0,00"
                autoComplete="off"
                required
                value={priceString && `R$ ${priceString}`}
                onChange={(e) => handleChangePrice(e, true)}
              />
            </CreationFormGroup>

            {discount && (
              <CreationFormGroup>
                <label htmlFor="original-price" className="required">
                  Preço sem desconto
                </label>
                <Input
                  id="original-price"
                  placeholder="R$ 0,00"
                  autoComplete="off"
                  required
                  value={fullPriceString && `R$ ${fullPriceString}`}
                  onChange={(e) => handleChangePrice(e, false)}
                />
              </CreationFormGroup>
            )}
          </PriceFormGroup>

          <BenefitsFormGroup>
            <label htmlFor="benefits">Benefícios</label>
            <Input
              id="benefits"
              placeholder="Benefícios"
              autoComplete="off"
              value={benefitsInput}
              onFocus={() => setBenefitFocused(true)}
              onBlur={() => setBenefitFocused(false)}
              onChange={(e) => setBenefitsInput(e.target.value)}
            />

            <div className="buttons">
              {editingBenefit > -1 && (
                <Button
                  danger
                  type="button"
                  onClick={() => {
                    setEditingBenefit(-1);
                  }}
                >
                  Cancelar alterações
                </Button>
              )}
              <Button
                primary
                disabled={!benefitsInput}
                type={benefitFocused ? 'submit' : 'button'}
                onClick={handleSubmitBenefit}
              >
                {
                  editingBenefit > -1
                    ? 'Salvar alterações'
                    : 'Adicionar benefício'
                }
              </Button>
            </div>

            <BenefitsList
              benefits={benefits}
              editingBenefit={editingBenefit}
              removeBenefit={handleRemoveBenefit}
              editBenefit={handleEditBenefit}
            />
          </BenefitsFormGroup>

          <CreationFormButtonGroup>
            <Button danger type="button" onClick={goToPlans}>
              Cancelar
            </Button>
            <Button primary>Salvar plano</Button>
          </CreationFormButtonGroup>
        </CreationForm>
      </TitleAndPlanContainer>
    </CreateAndEditPlansContainer>
  );
};

export default CreateAndEditPlans;
