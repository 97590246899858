import axios from 'axios';
import cleanObjectForApi from 'src/helpers/clean-object-for-api';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

httpClient.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem('admin-manole-api-token');
  if (config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.data) {
    cleanObjectForApi(config.data);
  }

  return config;
});

httpClient.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        if (
          !window.location.href.endsWith('/login') &&
          !(window.location.pathname === '/' || window.location.pathname === '')
        ) {
          window.location.href = `${window.location.origin}?redirect=${window.location.pathname || '/home'}`;
        }
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
