import styled, { css } from 'styled-components';
import { CreationFormGroup } from 'src/layouts/AppLayout/components/Forms';

interface CreateAndEditPlansContainerProps {
  isOnModal: boolean;
}

interface PriceFormGroupProps {
  isCheckbox?: boolean;
}

const CreateAndEditPlansContainer = styled.div<CreateAndEditPlansContainerProps>`
  flex: 1;
  width: 100%;

  ${({ isOnModal }) => isOnModal && css`
    width: 80vw;
    height: 75vh;
    overflow-y: auto;
  `}

  .flex {
    display: flex;
    gap: 16px;
  }
`;

const PriceFormGroup = styled(CreationFormGroup) <PriceFormGroupProps>`
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.isCheckbox
      ? css`
          gap: 7px;
          margin: 4px 0 2px;
        `
      : `
      gap: 10px;

      > div {
        flex: 1;
      }
    `}
`;

const BenefitsFormGroup = styled(CreationFormGroup)`
  .buttons {
    align-self: align-start;
    display: flex;
    gap: 10px;
    margin: 5px 0;
    height: 33px;
  }
`;

export { CreateAndEditPlansContainer, PriceFormGroup, BenefitsFormGroup };
