import axios from 'axios'
import urlJoin from 'url-join'
import httpClient from 'src/http-client'
import SignInCredentials from 'src/models/sign-in-credentials'
import User from 'src/models/user'

const baseUrl = process.env.REACT_APP_API_BASE_URL

interface ISuccessRefreshToken {
  token: string
  refresh_token: string
}

interface ISuccessLogin {
  token: string
  refresh_token: string
  user: User
}

export const login = async ({ email, password }: SignInCredentials): Promise<ISuccessLogin> => {
  const url = urlJoin(baseUrl!, '/sessions/login')

  const { data } = await axios.post<ISuccessLogin>(url, { email, password })

  return data
}

export const refreshTokenService = async (refreshToken: string): Promise<ISuccessRefreshToken> => {
  const { data } = await httpClient.post(`/sessions/refresh`, {
    refresh_token: refreshToken,
  })
  return data
}
