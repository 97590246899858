import httpClient from 'src/http-client';

import { getAuthors } from './authors';
import { getContents } from './contents';
import { getSpecialties } from './specialties';
import { listTagsService } from './tags';
import { getThemes } from './themes';

type IReportsParams = {
  start_at_gt: Date | string | number;
  start_at_lt: Date | string | number;
  finish_at_gt: Date | string | number;
  finish_at_lt: Date | string | number;
  format?: string;
  entity_ids: string[];
};

export enum ENTITY_TYPES {
  SPECIALTY = 'specialties',
  TAG = 'tags',
  CONTENT = 'contents',
  AUTHOR = 'authors',
  THEME = 'themes',
}

interface IGetEntitiesParams {
  type: ENTITY_TYPES;
  params: any;
}

interface IGetEntityStatsParams {
  type: ENTITY_TYPES;
  params: IReportsParams;
}

export const getEntityStatsHelper = async (params: any, type: string) => {
  return (
    await httpClient.get(`/statistics/${type}/`, {
      params,
      responseType: 'blob',
    })
  ).data;
};

export const getEntities = async ({ type, params }: IGetEntitiesParams) => {
  switch (type) {
    case ENTITY_TYPES.SPECIALTY:
      return getSpecialties(params);
    case ENTITY_TYPES.TAG:
      return listTagsService()
    case ENTITY_TYPES.AUTHOR:
      return {
        data: await getAuthors(params)
      }
    case ENTITY_TYPES.THEME:
      return getThemes(params)
    case ENTITY_TYPES.CONTENT:
      return getContents(params)
    default:
      return {
        count: 0,
        data: [],
      };
  }
};

export const getEntityStatsReport = async ({
  type,
  params: {
    entity_ids,
    ...restParams
  },
}: IGetEntityStatsParams) => {
  const names = {
    contents: 'content_ids',
    specialties: 'specialty_ids',
    authors: 'author_ids',
    themes: 'theme_ids',
    tags: 'tag_ids',
  };
  return getEntityStatsHelper({
    ...restParams,
    [names[type]]: entity_ids
  }, type);
};
