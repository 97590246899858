import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Input from 'src/components/Input';
import {
  LoginContainer,
  LoginForm,
  LoginLowerContent,
  LoginSubmit,
  LoginUpperText,
} from './style';
import { AiFillLock } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';
import showErrorMessage from 'src/helpers/show-error-message';
import { useHistory, useLocation } from 'react-router-dom';
import SystemError from 'src/models/error';
import useAuth from 'src/hooks/useAuth';
import SignInCredentials from 'src/models/sign-in-credentials';

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { signIn, refreshToken } = useAuth();
  const [credentials, setCredentials] = useState({} as SignInCredentials);

  const [, redirect] = useMemo(() => {
    return (
      location.search.split('&').find((param) => param.match('redirect=')) ||
      'redirect=/home'
    ).split('=');
  }, [location]);

  const handleChangeCredentials = (propName: string, newValue: any) => {
    setCredentials((cred) => ({ ...cred, [propName]: newValue }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await signIn(credentials);
      history.push(redirect);
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  };

  useEffect(() => {
    try {
      refreshToken().then((result) => {
        if(result) history.push(redirect)
      });
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginContainer>
      <LoginUpperText>
        <h3>Faça seu login abaixo</h3>
        <p>Seja bem-vindo a plataforma administrativa do LMS2</p>
      </LoginUpperText>
      <LoginForm onSubmit={handleSubmit}>
        <p className="enter">Entre com seu email</p>
        <Input
          icon={<MdEmail />}
          type="email"
          placeholder="Email"
          value={credentials.email}
          onChange={(e) => handleChangeCredentials('email', e.target.value)}
          required
        />
        <Input
          icon={<AiFillLock />}
          type="password"
          placeholder="Senha"
          value={credentials.password}
          onChange={(e) => handleChangeCredentials('password', e.target.value)}
          required
        />
        <LoginSubmit>Entrar</LoginSubmit>
      </LoginForm>
      <LoginLowerContent>
        <Link to="/forgot-password">Esqueceu sua senha?</Link>
        <Link to="/create-account">Criar nova conta</Link>
      </LoginLowerContent>
    </LoginContainer>
  );
};

export default Login;
