import styled from 'styled-components'

interface IStack {
  inline?: boolean
  gap?: string
  alignItems?: 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
}

export const Stack = styled.div<IStack>`
  display: flex;
  flex-direction: ${({ inline }) => inline ? 'row' : 'column'};
  align-items: ${({ alignItems = "center" }) => alignItems};
  gap: ${({ gap }) => gap};
`
