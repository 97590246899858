import styled from 'styled-components';

const UsersContainer = styled.div`
  width: 100%;
  flex: 1;
`;

export const ContainerInfo = styled.div`
  p {
    color: var(--text-black);
    line-height: 1.5rem;
  }
`

export const ContainerBtnAddUser = styled.div`
  padding: 0 0 16px;

  button {
    font-size: 1rem;
  }
`

export { UsersContainer };
