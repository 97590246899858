import React, { Dispatch, SetStateAction } from 'react';
import { CheckboxContainer } from './style';
import { AiOutlineCheck } from 'react-icons/ai';

interface CheckboxProps {
  id?: string;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, setChecked, id }) => {
  const toggleChecked = () => {
    setChecked(!checked);
  };
  return (
    <CheckboxContainer type="button" id={id} onClick={toggleChecked}>
      {checked && <AiOutlineCheck />}
    </CheckboxContainer>
  );
};

export default Checkbox;
