import httpClient from 'src/http-client';
import Plan, { IPlanType, IPlanVersion } from 'src/models/plan';

const getPlans = async () => {
  const { data } = await httpClient.get<Plan[]>(`/plans`)
  return data;
};

const getPlansTypesServices = async () => {
  const { data } = await httpClient.get<IPlanType[]>(`/plans/manole/types`)
  return data;
};

const getPlansVersionsServices = async () => {
  const { data } = await httpClient.get<IPlanVersion[]>(`/plans/manole/versions`)
  return data;
};

const getPlan = async (planId: string) => {
  const { data } = await httpClient.get<Plan>(`/plans/${planId}`);
  return data;
};

const createPlan = async (newPlan: Partial<Plan>) => {
  const lastPlan = Array.from(await getPlans()).pop();

  const planToCreate = {
    ...newPlan,
    manole_type_id: 123456,
    position: (lastPlan?.position || 0) + 1,
  }

  const { data } = await httpClient.post(`/plans`, planToCreate);

  return data
};

const updatePlan = async (planId: string, newPlan: Partial<Plan>) => {
  delete newPlan.plan_id;
  delete newPlan.created_at;
  delete newPlan.updated_at;
  delete newPlan.deleted_at;

  const { data } = await httpClient.put(`/plans/${planId}`, { ...newPlan })

  return data
};

const deletePlan = async (planId: string) => {
  const { data } = await httpClient.delete(`/plans/${planId}`);
  return data
};

const togglePlanActive = async (planId: string, shouldActive: boolean) => {
  const { data } = await httpClient.put(`/plans/${planId}`, {
    info: {
      is_active: shouldActive,
    },
  })
  return data
};

const repositionPlan = async (planId: string, position: number) => {
  const { data } = await httpClient.put(`/plans/${planId}`, { position })
  return data;
};

export {
  getPlans,
  getPlansTypesServices,
  getPlansVersionsServices,
  getPlan,
  createPlan,
  updatePlan,
  deletePlan,
  togglePlanActive,
  repositionPlan,
};
