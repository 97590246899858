import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SignInCredentials from '../models/sign-in-credentials';
import User from '../models/user';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router'
import AuthContext from 'src/contexts/AuthContext';
import { login as loginService, refreshTokenService } from 'src/services/login';
import { getUserMeService } from 'src/services/users';
import Swal from 'sweetalert2';


const AuthContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = useState<User | undefined>();

  const signIn = async (signInCredentials: SignInCredentials) => {
    const { token, user, refresh_token } = await loginService(signInCredentials);

    if (!user.is_admin) {
      throw new Error('Você não possui permissão para acessar a plataforma administrativa!');
    }

    window.localStorage.setItem('admin-manole-api-token', token);
    window.localStorage.setItem('admin-manole-api-refresh-token', refresh_token);
  };

  const signOut = () => {
    window.localStorage.removeItem('admin-manole-api-token');
    window.localStorage.removeItem('admin-manole-api-refresh-token');
    history.push('/');
  };

  const refreshToken = useCallback(async () => {
    const currentRefreshToken = window.localStorage.getItem('admin-manole-api-refresh-token');

    if (!currentRefreshToken) return false;

    try {
      const { token, refresh_token } = await refreshTokenService(currentRefreshToken);

      window.localStorage.setItem('admin-manole-api-token', token);
      window.localStorage.setItem('admin-manole-api-refresh-token', refresh_token);

      return true;
    } catch (e) {
      return false;
    }
  }, []);

  const isOnLoginPage = useMemo(() => {
    return location.pathname === '/';
  }, [location.pathname]);

  const getUser = useCallback(async () => {
    if (!isOnLoginPage) {
      try {
        const user = await getUserMeService();
        setUser(user);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'Você está deslogado. Faça login para continuar!',
        });
      }
    }
  }, [isOnLoginPage]);

  const isAuthenticated = useMemo(() => !!user, [user]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn, signOut, refreshToken }}>
      {(isAuthenticated || isOnLoginPage) && children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
