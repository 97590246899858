import React from 'react';
import { CreationFormGroup, SelectContainer } from './style';

interface SelectProps extends React.HTMLAttributes<HTMLSelectElement> {
  id: string
  value?: string | number
  required?: boolean
  label?: string
  flexGrow?: number
  options?: {
    name: string
    value: string
  }[]
}

export const Select: React.FC<SelectProps> = ({ id, value, label, required, options, style, flexGrow, ...rest }) => {
  return (
    <CreationFormGroup style={{ flexGrow }}>
      {label && (
        <label htmlFor={id} className={required ? "required" : undefined}>
          {label}
        </label>
      )}

      <SelectContainer>
        <select id={id} style={style} value={value} {...rest}>
          <option value="">Selecione um nome</option>
          {options?.map((opt) => (
            <option value={opt.value}>{opt.name}</option>
          ))}
        </select>
      </SelectContainer>
    </CreationFormGroup>
  );
};
