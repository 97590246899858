import { MultiselectContainer, MultiselectCustom } from './style';

interface IFormMultiselect {
  id?: string;
  label?: string;
  placeholder?: string;
  loading?: boolean;
  displayValue: string;
  options: any;
  selectedValues: any;
  onChange: (selectedList: any, selectedItem: any) => void;
  disabled?: boolean;
}

export function Multiselect({
  id,
  label,
  placeholder,
  displayValue,
  options,
  selectedValues,
  onChange,
  loading,
  disabled,
}: IFormMultiselect) {
  return (
    <MultiselectContainer>
      {label && <label htmlFor={label}>{label}</label>}

      <MultiselectCustom
        id={id}
        placeholder={placeholder || label}
        displayValue={displayValue}
        options={options}
        showArrow={true}
        avoidHighlightFirstOption={true}
        hidePlaceholder
        selectedValues={selectedValues}
        onSelect={onChange}
        onRemove={onChange}
        showCheckbox
        loading={loading}
        // @ts-ignore
        loadingMessage="Carregando..."
        disable={disabled}
        style={{
          searchBox: {
            borderRadius: '8px',
            background: disabled ? '#E1E3E6' : '#F5F8FA',
            padding: '12px',
          },
          chips: {
            background: 'var(--primary)',
          },
        }}
      />
    </MultiselectContainer>
  );
}

export default Multiselect;
