import FeatureList from 'src/layouts/AppLayout/components/FeatureList';
import styled from 'styled-components';

const StatisticsContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const StatisticsFeatureList = styled(FeatureList)``;

export { StatisticsContainer, StatisticsFeatureList };
