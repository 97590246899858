import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import { AcademicContainer, AcademicFeatureList } from './style';
import home from 'src/assets/home.svg';
import FeatureCard from 'src/layouts/AppLayout/components/FeatureCard';
import Button from 'src/components/Button';

const Academic: React.FC = () => {
  const history = useHistory();

  const goTo = (address: string) => {
    history.push(address);
  };

  return (
    <AcademicContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Acadêmico</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Personalização</strong>,
            <span>Acadêmico</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <AcademicFeatureList>
        <FeatureCard>
          <span className="title">Especialidades</span>

          <span className="description">
            Liste as especialidades existentes, crie ou edite uma nova agora!
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/customization/academic/specialties')}
          >
            Ver mais
          </Button>
        </FeatureCard>

        <FeatureCard>
          <span className="title">Temas</span>

          <span className="description">
            Liste os temas existentes, crie ou edite um novo agora!
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/customization/academic/themes')}
          >
            Ver mais
          </Button>
        </FeatureCard>

        <FeatureCard>
          <span className="title">Conteúdos</span>

          <span className="description">
            Liste os conteúdos existentes, crie ou edite um novo agora!
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/customization/academic/contents')}
          >
            Ver mais
          </Button>
        </FeatureCard>

        <FeatureCard>
          <span className="title">Autores</span>

          <span className="description">
            Liste os autores existentes, crie ou edite um novo agora!
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/customization/academic/authors')}
          >
            Ver mais
          </Button>
        </FeatureCard>

        <FeatureCard>
          <span className="title">Tags</span>

          <span className="description">
            Liste as tags existentes, crie ou edite uma nova agora!
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/customization/academic/tags')}
          >
            Ver mais
          </Button>
        </FeatureCard>
      </AcademicFeatureList>
    </AcademicContainer>
  );
};

export default Academic;
