import styled, { css } from 'styled-components';

interface BenefitRowProps {
  editing: boolean;
}

const BenefitsListContainer = styled.div`
  .row {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    padding: 3px 0;

    button {
      align-self: center;
    }
  }

  .benefit {
    margin-left: 5px;
  }

  .benefit,
  .empty {
    align-self: center;
    color: var(--text-gray);
  }
`;

const BenefitRow = styled.div<BenefitRowProps>`
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  padding: 3px 0;

  button {
    align-self: center;
    :first-of-type {
      border: 1px solid transparent;
    }
  }

  ${(props) =>
    !props.editing &&
    css`
      button:last-of-type {
        border: 1px solid transparent;
      }
    `}
`;

export { BenefitsListContainer, BenefitRow };
