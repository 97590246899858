import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import CreateAndSearchContainer from 'src/layouts/AppLayout/components/CreateAndSearchContainer';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import Table from 'src/layouts/AppLayout/components/Table';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import Button from 'src/components/Button';
import TableAndTitleContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import { SpecialtiesContainer } from './style';
import home from 'src/assets/home.svg';
import ActionButtonsContainer from 'src/layouts/AppLayout/components/ActionButtonsContainer';
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import makeQuestion from 'src/helpers/make-question';
import showErrorMessage from 'src/helpers/show-error-message';
import showSuccessMessage from 'src/helpers/show-success-message';
import SystemError from 'src/models/error';
import {
  deleteSpecialty as deleteSpecialtyService,
  getSpecialties as getSpecialtiesService,
  toggleSpecialtyActive as toggleSpecialtyActiveService,
} from 'src/services/specialties';
import useLoading from 'src/hooks/useLoading';
import Specialty from 'src/models/specialty';
import TableThumbnail from 'src/layouts/AppLayout/components/TableThumbnail';
import AppContext from 'src/contexts/AppContext';

const Specialties: React.FC = () => {
  const history = useHistory();
  const [specialties, setSpecialties] = useState([] as Specialty[]);
  const { setIsLoading } = useLoading();
  const { selectedPartner } = useContext(AppContext);

  const getSpecialties = useCallback(async () => {
    setIsLoading(true);

    const { data: specialties } = await getSpecialtiesService(
      selectedPartner?.segmentation_item_id
        ? {
          segmentation_item_ids: [
            selectedPartner.segmentation_item_id ||
            process.env.REACT_APP_MANOLE_UUID ||
            '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
          ],
        }
        : {}
    );
    setSpecialties(specialties);

    setIsLoading(false);
  }, [selectedPartner?.segmentation_item_id, setIsLoading]);

  const createSpecialty = () => {
    history.push(`/customization/academic/specialties/create-specialty`);
  };

  const editSpecialty = useCallback(
    (specialtyId: string) => {
      history.push(
        `/customization/academic/specialties/edit-specialty/${specialtyId}`
      );
    },
    [history]
  );

  const removeSpecialty = useCallback(
    async (specialtyId: string) => {
      const result = await makeQuestion('Deseja excluir esta especialidade?');

      if (result.isConfirmed) {
        try {
          await deleteSpecialtyService(specialtyId);

          showSuccessMessage('Especialidade removida com sucesso');

          await getSpecialties();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getSpecialties]
  );

  const toggleSpecialtyActive = useCallback(
    async (specialtyId: string, isActive: boolean) => {
      const result = await makeQuestion(
        `Deseja ${isActive ? 'inativar' : 'ativar'} esta especialidade?`
      );

      if (result.isConfirmed) {
        try {
          const shouldActive = !isActive;
          await toggleSpecialtyActiveService(specialtyId, shouldActive);

          showSuccessMessage(
            `Especialidade ${shouldActive ? 'ativada' : 'inativada'
            } com sucesso`
          );

          await getSpecialties();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getSpecialties]
  );

  const specialtiesToShow = useMemo(() => {
    return (specialties || []).map((specialty) => ({
      ...specialty,
      thumbnail: specialty?.images?.thumbnail ? (
        <TableThumbnail src={specialty?.images?.thumbnail} />
      ) : (
        <></>
      ),
      actions: (
        <ActionButtonsContainer>
          <Button
            primary
            table
            type="button"
            onClick={() => editSpecialty(specialty.content_id)}
          >
            <AiOutlineEdit />
            <span>Editar</span>
          </Button>
          <Button
            warning
            table
            type="button"
            onClick={() =>
              toggleSpecialtyActive(specialty.content_id, specialty.is_active)
            }
          >
            {specialty.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}{' '}
            {specialty.is_active ? 'Inativar' : 'Ativar'}
          </Button>
          <Button
            dangerFill
            table
            type="button"
            onClick={() => removeSpecialty(specialty.content_id)}
          >
            <BsTrashFill />
            <span>Excluir</span>
          </Button>
        </ActionButtonsContainer>
      ),
    }));
  }, [editSpecialty, removeSpecialty, specialties, toggleSpecialtyActive]);

  useEffect(() => {
    getSpecialties();
  }, [getSpecialties]);

  return (
    <SpecialtiesContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Especialidades</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Personalização</strong>,
            <Link to="/customization/academic">Acadêmico</Link>,
            <span>Especialidades</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <CreateAndSearchContainer>
        <Button type="button" success onClick={createSpecialty}>
          Criar nova especialidade
        </Button>
      </CreateAndSearchContainer>

      <TableAndTitleContainer>
        <PageTitle dark medium>
          Especialidades
        </PageTitle>
        <Table
          headerConfigs={[
            {
              label: 'Imagem',
              propName: 'thumbnail',
            },
            {
              label: 'Nome',
              propName: 'name',
            },
            {
              label: 'Descrição',
              propName: 'description',
            },
            {
              label: 'Ações',
              propName: 'actions',
              attributes: { style: { width: '340px' } },
            },
          ]}
          items={specialtiesToShow}
        />
      </TableAndTitleContainer>
    </SpecialtiesContainer>
  );
};

export default Specialties;
