import styled, { css } from 'styled-components';

interface TagsContainerProps {
  isOnModal: boolean;
}

const TagsContainer = styled.div<TagsContainerProps>`
  flex: 1;
  width: 100%;

  ${(props) =>
    props.isOnModal &&
    css`
      width: 80vw;
      height: 75vh;
      overflow-y: auto;
    `}
`;

const SelectedTags = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  & .selected-Tags {
    flex: 1;
    max-width: 100%;
  }
`;

export { TagsContainer, SelectedTags };
