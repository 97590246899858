import styled, { css } from 'styled-components';

interface ICreateAndEditUserContainer {
  isOnModal: boolean;
}

export const CreateAndEditUserContainer = styled.div<ICreateAndEditUserContainer>`
  flex: 1;
  width: 100%;

  .flex {
    display: flex;
    gap: 16px;
  }

  ${({ isOnModal }) => isOnModal && css`
    width: 80vw;
    height: 100%;
    overflow-y: auto;
  `}
`;
