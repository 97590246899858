import styled, { css } from 'styled-components';

interface ButtonProps {
  danger?: boolean;
  dangerFill?: boolean;
  primaryFill?: boolean;
  primary?: boolean;
  warning?: boolean;
  circle?: boolean;
  small?: boolean;
  table?: boolean;
  outline?: boolean;
  pdf?: boolean;
  csv?: boolean;
  success?: boolean;
}

const Button = styled.button<ButtonProps>`
  outline: none;
  border: none;
  background: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  color: var(--primary);
  border-radius: 4px;
  transition: all 0.5s;
  height: 42px;

  :hover {
    box-shadow: 0px 0px 10px 0px #f7fafc;
  }

  :disabled {
    background: var(--gray);
    cursor: not-allowed;
    :hover {
      box-shadow: unset;
    }
  }

  ${(props) =>
    props.success &&
    css`
      background: var(--success);
      color: var(--white);

      :hover {
        box-shadow: 0 0 3px 1px var(--success);
      }
    `}
  ${(props) =>
    props.danger &&
    css`
      background: transparent;
      color: var(--danger);
      border: solid 1px var(--danger);

      :hover {
        box-shadow: 0 0 3px 1px var(--danger);
      }
    `}
  ${(props) =>
    props.dangerFill &&
    css`
      color: var(--white);
      background: var(--danger);

      :hover {
        box-shadow: 0 0 3px 1px var(--danger);
      }
    `}
    ${(props) =>
    props.primaryFill &&
    css`
      color: var(--white);
      background: var(--primary);

      :hover {
        box-shadow: 0 0 3px 1px var(--primary);
      }
    `}
  ${(props) =>
    props.primary &&
    css`
      color: var(--white);
      background: var(--primary);

      :hover {
        box-shadow: 0 0 3px 1px var(--primary);
      }
    `}
  ${(props) =>
    props.small &&
    css`
      padding: 4px;
    `}
    ${(props) =>
    props.table &&
    css`
      padding: 6px;
    `}
    ${(props) =>
    props.circle &&
    css`
      padding: 0;
      ${props.small
        ? css`
            width: 32px !important;
            height: 32px !important;
          `
        : css`
            width: 48px !important;
            height: 48px !important;
          `}
      border-radius: 50% !important;
    `}
    ${(props) =>
    props.warning &&
    css`
      color: var(--text-black);
      background: var(--warning);

      :hover {
        box-shadow: 0 0 3px 1px var(--warning);
      }
    `}
    ${(props) =>
    props.outline &&
    css`
      border: solid 1px var(--primary);
    `}
    ${(props) =>
    props.pdf &&
    css`
      color: var(--white);
      background: var(--pdf-color);

      :hover {
        box-shadow: 0 0 3px 1px var(--pdf-color);
      }
    `}
    ${(props) =>
    props.csv &&
    css`
      color: var(--white);
      background: var(--csv-color);

      :hover {
        box-shadow: 0 0 3px 1px var(--csv-color);
      }
    `}
`;

export default Button;
