import React, { useCallback, useEffect, useState } from 'react';
import { AiFillFileExcel, AiOutlineFilePdf } from 'react-icons/ai';
import {
  FilterForm,
  FilterFormInputGroup,
  FilterFormButtonGroup,
} from 'src/layouts/AppLayout/components/Filters';
import Button from 'src/components/Button';
import firstDayInPreviousMonth from 'src/helpers/first-day-previous-month';
import lastDayOnNextMonth from 'src/helpers/last-day-next-month';
import { LocalContainer } from './style';
import download from 'downloadjs';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import { Link } from 'react-router-dom';
import home from 'src/assets/home.svg';
import showErrorMessage from 'src/helpers/show-error-message';
import SystemError from 'src/models/error';
import Multiselect from 'src/components/MultiSelect';
import useLoading from 'src/hooks/useLoading';
import useApp from 'src/hooks/useApp';
import { ENTITY_TYPES, getEntities, getEntityStatsReport } from 'src/services/statistics';
import { DatesFilter, IDateRanges } from 'src/layouts/AppLayout/components/DatesFilters';
import Specialty from 'src/models/specialty';
import Content from 'src/models/content';
import Theme from 'src/models/theme';
import { ITag } from 'src/services/tags';
import Author from 'src/models/author';

interface StatsCommonPageParams {
  pageName: string;
  entityName: string;
  type: ENTITY_TYPES
}

type Entities = Content | Specialty | Theme | ITag | Author;

const StatsCommonPage: React.FC<StatsCommonPageParams> = ({ pageName, entityName, type}) => {
  const [dates, setDates] = useState({
    start_at_gt: firstDayInPreviousMonth(),
    start_at_lt: lastDayOnNextMonth(),
    finish_at_gt: firstDayInPreviousMonth(),
    finish_at_lt: lastDayOnNextMonth(),
  } as IDateRanges);

  const { setIsLoading } = useLoading();

  const [selectedEntities, setSelectedEntities] = useState<Entities[]>([]);
  const [entities, setEntities] = useState<Entities[] | null>(null);
  const { selectedPartner } = useApp();

  const handleDateChange = (date: Partial<IDateRanges>) => {
    setDates({ ...dates,  ...date});
  };

  const validate = useCallback(() => {
    if (!selectedEntities.length) {
      throw new Error(`Selecione um/a ou mais ${entityName} à incluir no relatório`);
    }
    if (
      !dates.start_at_gt ||
      !dates.start_at_lt ||
      !dates.finish_at_gt ||
      !dates.finish_at_lt
    ) {
      throw new Error('Informe todas as datas para geração do relatório!');
    }
  }, [dates.finish_at_gt, dates.finish_at_lt, dates.start_at_gt, dates.start_at_lt, entityName, selectedEntities.length]);


  const downloadReport = async (format: string) => {
    try {
      validate();

      setIsLoading(true);

      const report = await getEntityStatsReport({
        params: {
          ...dates,
        format,
        // @ts-ignore
        entity_ids: selectedEntities.map((entity) => entity?.content_id || entity?.tag_id || entity.author_id),
        },
        type
      });

      download(
        report,
        `Estatística-${entityName}${new Intl.DateTimeFormat('pt-BR', {
          dateStyle: 'short',
          timeStyle: 'short',
        }).format(new Date())}.${type}`
      );
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getEntities({
      type,
      params: type !== ENTITY_TYPES.AUTHOR ? {
        segmentation_item_ids: [
          selectedPartner?.segmentation_item_id ||
            process.env.REACT_APP_MANOLE_UUID ||
            '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
        ]
      }: {}
    }).then(({ data: entities }) => {
      setEntities(
        entities?.map((entity, idx) => ({
          ...entity,
          // @ts-ignore
          name: `${idx + 1} - ${entity.name || entity.title}`,
        }))
      );
    });

    return () => {
      setSelectedEntities([]);
      setEntities(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <LocalContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>{pageName}</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <Link to="/others/reports">Estatísticas</Link>,
            <span>{pageName}</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <FilterForm>
        <FilterFormInputGroup>
          <label htmlFor="entities" id="entities">
            {entityName}
          </label>
          <Multiselect
            id={entityName}
            placeholder={`Selecione as ${entityName}`}
            displayValue="name"
            options={entities ?? []}
            loading={!entities}
            selectedValues={selectedEntities}
            onChange={(selectedList: any) => setSelectedEntities(selectedList)}
          />
        </FilterFormInputGroup>
        <DatesFilter dates={dates} handleDateChange={handleDateChange} />

        <FilterFormButtonGroup>
          <Button primary type="button" onClick={() => downloadReport('pdf')}>
            <AiOutlineFilePdf size={16} />
            <span>Relatório (PDF)</span>
          </Button>
          <Button primary type="button" onClick={() => downloadReport('csv')}>
            <AiFillFileExcel size={16} />
            <span>Relatório (CSV)</span>
          </Button>
        </FilterFormButtonGroup>
      </FilterForm>
    </LocalContainer>
  );
};

export default StatsCommonPage;
