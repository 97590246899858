const cleanObjectForApi = (object: any) => {
  for (let key of Object.keys(object)) {
    if (
      !object[key] &&
      object[key] !== false &&
      object[key] !== '' &&
      object[key] !== 0
    ) {
      delete object[key];
    }
  }
};

export default cleanObjectForApi;
