import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import { PasswordRules, validatePassword } from 'src/components/PasswordRules';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import TitleAndTagContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import { CreationForm, CreationFormButtonGroup, CreationFormGroup } from 'src/layouts/AppLayout/components/Forms';
import { CreateAndEditUserContainer } from './style';
import { getUserService, signUpService, updateUserService } from 'src/services/users';
import { IUser, IUserForm, IUserPost, IUserPut } from 'src/models/user';
import SystemError from 'src/models/error';
import { Mask } from 'src/helpers/Mask';
import checkEmptyString from 'src/helpers/check-empty-string';
import showErrorMessage from 'src/helpers/show-error-message';
import showSuccessMessage from 'src/helpers/show-success-message';
import { validateCPF } from 'src/helpers/validateCPF';
import { validateBirthDate } from 'src/helpers/validateBirthDate';
import home from 'src/assets/home.svg';

const userDefault: IUserForm = {
  name: '',
  last_name: '',
  cpf: '',
  info: {
    birth_date: '',
    specialty: '',
    phone: '',
  },
  email: '',
}

const keysInfoArray = Object.keys(userDefault.info)

interface ICreateAndEditUserParams {
  userId: string
}

export function CreateAndEditUser() {
  const { userId } = useParams<ICreateAndEditUserParams>();

  const history = useHistory();
  const goToUsers = () => history.push('/users')

  const [user, setUser] = useState<IUserForm>(userDefault)
  const [currentUser, setCurrentUser] = useState<IUser>()
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [password, setPassword] = useState('')

  const [isEditing, setIsEditing] = useState(false)

  const getUser = async (_userId: string) => {
    try {
      const userData = await getUserService(_userId)

      if (!userData) throw new Error('Usuário não encontrado!')

      setCurrentUser(userData)
      setUser({
        name: userData.name,
        last_name: userData.last_name,
        cpf: userData.cpf,
        info: {
          birth_date: userData.info.birth_date || '',
          specialty: userData.info.specialty || '',
          phone: userData.info.phone || '',
        },
        email: userData.email,
      })
    } catch (error) {
      showErrorMessage(error as SystemError)
    }
  }

  useEffect(() => {
    const isPathEditUser = history.location.pathname.includes('/users/edit/')
    setIsEditing(isPathEditUser)

    if (userId) getUser(userId)
  }, [history, userId])

  const clearForm = () => {
    setUser(userDefault)
    setPassword('')
    setPasswordConfirmation('')
  }

  const validateForm = () => {
    if (checkEmptyString(user.name)) {
      throw new Error('Informe o nome!');
    }

    if (checkEmptyString(user.last_name)) {
      throw new Error('Informe o sobrenome!');
    }

    if (checkEmptyString(user.cpf)) {
      throw new Error('Informe o CPF!');
    }

    if (!validateCPF(user.cpf)) {
      throw new Error('Informe um CPF válido!');
    }

    if (checkEmptyString(user.info.birth_date)) {
      throw new Error('Informe a data de nascimento!');
    }

    if (!validateBirthDate(user.info.birth_date, 18)) {
      throw new Error('Informe uma data de nascimento válida!');
    }

    if (checkEmptyString(user.info.specialty)) {
      throw new Error('Informe a especialidade!');
    }

    if (checkEmptyString(user.info.phone)) {
      throw new Error('Informe o celular!');
    }

    if (checkEmptyString(user.email)) {
      throw new Error('Informe o email!');
    }

    if (isEditing) return

    if (checkEmptyString(password)) {
      throw new Error('Informe a senha!');
    }

    if (!validatePassword(password)) {
      throw new Error('Nova senha inválida.')
    }

    if (password !== passwordConfirmation) {
      throw new Error('A senha e a confirmação de senha devem ser iguais!');
    }
  }

  const handleChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target

    if (name === 'phone' || name === 'cpf') {
      value = Mask[name](value)
    }

    const isPropInfo = keysInfoArray.includes(name)

    if (!isPropInfo) {
      setUser((oldValue: IUserForm) => ({
        ...oldValue,
        [name]: value
      }))
    }
    else {
      setUser((oldValue: IUserForm) => ({
        ...oldValue,
        info: {
          ...oldValue.info,
          [name]: value
        }
      }))
    }
  }

  const handleSubmit = async () => {
    try {
      validateForm()

      if (!isEditing) {
        const userPost: IUserPost = {
          ...user,
          password,
        }

        await signUpService(userPost)

        showSuccessMessage('Usuário cadastrado com sucesso!')
      }
      else {
        const userPut: IUserPut = {
          name: user.name,
          last_name: user.last_name,
          cpf: user.cpf,
          info: {
            ...currentUser?.info,
            birth_date: user.info.birth_date,
            phone: user.info.phone,
            specialty: user.info.specialty,
          }
        }

        await updateUserService(userId, userPut)

        showSuccessMessage('Usuário editado com sucesso!')
      }

      clearForm()
      goToUsers()
    } catch (error) {
      showErrorMessage(error as SystemError)
    }
  }

  return (
    <CreateAndEditUserContainer isOnModal={true}>
      <TitleAndBreadcrumbContainer>
        <PageTitle>{isEditing ? 'Editar' : 'Criar'} Usuário</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <Link to="/users">Usuários</Link>,
            <span>{isEditing ? 'Editar' : 'Criar'} usuário</span>
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <TitleAndTagContainer>
        <PageTitle dark medium>
          {isEditing ? 'Editar' : 'Criar'} Usuário
        </PageTitle>

        <CreationForm onSubmit={handleSubmit}>
          <div className="flex">
            <CreationFormGroup>
              <label htmlFor="name" className="required">
                Nome
              </label>
              <Input
                name="name"
                placeholder="Nome"
                value={user.name}
                onChange={handleChangeForm}
                required
              />
            </CreationFormGroup>

            <CreationFormGroup>
              <label htmlFor="last_name" className="required">
                Sobrenome
              </label>
              <Input
                name="last_name"
                placeholder="Sobrenome"
                value={user.last_name}
                onChange={handleChangeForm}
                required
              />
            </CreationFormGroup>
          </div>

          <div className="flex">
            <CreationFormGroup>
              <label htmlFor="cpf" className="required">
                CPF
              </label>
              <Input
                name="cpf"
                placeholder="CPF"
                value={Mask.cpf(user.cpf)}
                onChange={handleChangeForm}
                required
              />
            </CreationFormGroup>

            <CreationFormGroup>
              <label htmlFor="birth_date" className="required">
                Data de nascimento
              </label>
              <Input
                name="birth_date"
                placeholder="Data de nascimento"
                value={user.info.birth_date}
                onChange={handleChangeForm}
                type="date"
                required
              />
            </CreationFormGroup>

            <CreationFormGroup>
              <label htmlFor="specialty" className="required">
                Especialidade
              </label>
              <Input
                name="specialty"
                placeholder="Especialidade"
                value={user.info.specialty}
                onChange={handleChangeForm}
                required
              />
            </CreationFormGroup>
          </div>

          <div className="flex">
            <CreationFormGroup>
              <label htmlFor="phone" className="required">
                Celular
              </label>
              <Input
                name="phone"
                placeholder="Celular"
                value={user.info.phone}
                onChange={handleChangeForm}
                required
              />
            </CreationFormGroup>

            <CreationFormGroup>
              <label htmlFor="email" className="required">
                Email
              </label>
              <Input
                name="email"
                placeholder="Email"
                value={user.email}
                onChange={handleChangeForm}
                disabled={isEditing}
                required
              />
            </CreationFormGroup>
          </div>

          {!isEditing && (
            <div className="flex">
              <CreationFormGroup>
                <label htmlFor="password" className="required">
                  Senha
                </label>
                <Input
                  name="password"
                  placeholder="Senha"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  type="password"
                  required
                />
              </CreationFormGroup>

              <CreationFormGroup>
                <label htmlFor="password" className="required">
                  Confirme sua senha
                </label>
                <Input
                  name="passwordConfirmation"
                  placeholder="Confirme sua senha"
                  value={passwordConfirmation}
                  onChange={({ target }) => setPasswordConfirmation(target.value)}
                  type="password"
                  required
                />
              </CreationFormGroup>
            </div>
          )}

          {!isEditing && <PasswordRules password={password} />}

          <CreationFormButtonGroup>
            <Button type="button" onClick={goToUsers} danger>
              Cancelar
            </Button>

            <Button type="button" onClick={handleSubmit} primary>
              Salvar
            </Button>
          </CreationFormButtonGroup>
        </CreationForm>
      </TitleAndTagContainer>
    </CreateAndEditUserContainer>
  );
};
