import styled from 'styled-components';

interface ITableAndTitleContainer {
  padding?: string;
}

const TableAndTitleContainer = styled.div<ITableAndTitleContainer>`
  background: white;
  border-radius: 4px;

  h2 {
    padding: ${({ padding = '16px 24px' }) => padding};
  }
`;

export default TableAndTitleContainer;
