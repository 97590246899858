import { FC } from 'react';
import Input from 'src/components/Input';
import { FilterFormGroup, FilterFormInputGroup } from '../Filters';

interface IDatesFilter {
  dates: IDateRanges;
  handleDateChange: (param: Partial<IDateRanges>) => void;
}

export interface IDateRanges {
  start_at_lt: string | number;
  start_at_gt: string | number;
  finish_at_lt: string | number;
  finish_at_gt: string | number;
}

export const DatesFilter: FC<IDatesFilter> = ({
  dates: { start_at_gt, start_at_lt, finish_at_gt, finish_at_lt },
  handleDateChange,
}) => {
  return (
    <>
      <FilterFormGroup columns={2}>
        <FilterFormInputGroup>
          <label htmlFor="start_at_gt">Inicio em</label>
          <Input
            type="date"
            value={start_at_gt}
            max={start_at_lt}
            onChange={(e) =>
              handleDateChange({ start_at_gt: e.target.value })
            }
          />
        </FilterFormInputGroup>

        <FilterFormInputGroup>
          <label htmlFor="start_at_lt">Até</label>
          <Input
            type="date"
            value={start_at_lt}
            min={start_at_gt}
            onChange={(e) =>
              handleDateChange({ start_at_lt: e.target.value })
            }
          />
        </FilterFormInputGroup>
      </FilterFormGroup>

      <FilterFormGroup columns={2}>
        <FilterFormInputGroup>
          <label htmlFor="finish_at_gt">Fim em</label>
          <Input
            type="date"
            value={finish_at_gt}
            max={finish_at_lt}
            onChange={(e) =>
              handleDateChange({ finish_at_gt: e.target.value })
            }
          />
        </FilterFormInputGroup>

        <FilterFormInputGroup>
          <label htmlFor="finish_at_lt">Até</label>
          <Input
            type="date"
            value={finish_at_lt}
            min={finish_at_gt}
            onChange={(e) =>
              handleDateChange({ finish_at_lt: e.target.value })
            }
          />
        </FilterFormInputGroup>
      </FilterFormGroup>
    </>
  );
};

export default IDatesFilter;
