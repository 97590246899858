import styled from 'styled-components';

export const CreationFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-weight: 600;
    color: var(--text-gray);
    font-size: 0.8rem;

    &.required {
      ::after {
        content: ' *';
        color: red;
        font-size: 0.7rem;
      }
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  box-shadow: 0px 1px 3px 0px var(--gray);
  width: 100%;
  border-radius: 4px;
  padding: 0 4px;

  * {
    color: var(--gray) !important;
  }

  & .icon {
    padding: 0 4px;
  }

  select {
    padding: 12px 0px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    width: 100%;

    ::placeholder {
      font-size: 0.9em;
    }
  }
`;
