import { PasswordRulesContainer } from "./style"
import { ReactComponent as CheckIcon } from 'src/assets/check.svg';

interface IPasswordRules {
  password: string
}

interface IIcon {
  visibility?: boolean
}

const Icon = ({ visibility }: IIcon) => {
  return (
    <div className="check-icon">
      {visibility && <CheckIcon />}
    </div>
  )
}

export const validatePassword = (password: string) => {
  const validate = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(password)
  return validate
}

export function PasswordRules({ password }: IPasswordRules) {
  const hasUppercase = /[A-Z]{1,}/.test(password)
  const hasLowercase = /[a-z]{1,}/.test(password)
  const hasNumber = /\d{1,}/.test(password)
  const hasMinEightCharacters = password.length >= 8

  return (
    <PasswordRulesContainer>
      <span>A senha deve ter:</span>

      <ul>
        <li>
          <Icon visibility={hasUppercase} /> 1 letra maiúscula
        </li>

        <li>
          <Icon visibility={hasLowercase} /> 1 letra minúscula
        </li>

        <li>
          <Icon visibility={hasNumber} /> 1 número
        </li>

        <li>
          <Icon visibility={hasMinEightCharacters} /> Minimo de 8 caracteres
        </li>
      </ul>
    </PasswordRulesContainer>
  )
}
