import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
} from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import Button from 'src/components/Button';
import { PlansActionButtonsContainer, PlansContainer } from './style';
import CreateAndSearchContainer from 'src/layouts/AppLayout/components/CreateAndSearchContainer';
import TableAndTitleContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import Table from 'src/layouts/AppLayout/components/Table';
import useLoading from 'src/hooks/useLoading';
import {
  getPlans as getPlansService,
  deletePlan as deletePlanService,
  togglePlanActive as togglePlanActiveService,
  repositionPlan as repositionPlanService,
} from 'src/services/plans';
import SystemError from 'src/models/error';
import Plan from 'src/models/plan';
import makeQuestion from 'src/helpers/make-question';
import showSuccessMessage from 'src/helpers/show-success-message';
import showErrorMessage from 'src/helpers/show-error-message';
import home from 'src/assets/home.svg';

const Plans: React.FC = () => {
  const history = useHistory();
  const { setIsLoading } = useLoading();

  const [plans, setPlans] = useState([] as Plan[]);
  const [positions, setPositions] = useState<number[]>([]);

  const getPlans = useCallback(async () => {
    setIsLoading(true);
    let positions = [];

    try {
      const plans = await getPlansService();
      const length = plans.length;

      if (length) {
        positions = [plans[0].position, plans[length - 1].position];
        setPositions(positions);
      }

      setPlans(plans);
    } catch (error) {
      showErrorMessage(error as SystemError);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useEffect(() => { getPlans(); }, [getPlans]);

  const createPlan = () => {
    history.push(`/customization/plans/create-plan`);
  };

  const editPlan = useCallback(
    (planId: string) => {
      history.push(`/customization/plans/edit-plan/${planId}`);
    },
    [history]
  );

  const removePlan = useCallback(
    async (planId: string) => {
      const result = await makeQuestion('Deseja excluir este plano?');

      if (result.isConfirmed) {
        try {
          await deletePlanService(planId);

          showSuccessMessage('Plano removido com sucesso');

          await getPlans();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getPlans]
  );

  const togglePlanActive = useCallback(
    async (planId: string, isActive: boolean) => {
      const result = await makeQuestion(
        `Deseja ${isActive ? 'inativar' : 'ativar'} esta especialidade?`
      );

      if (result.isConfirmed) {
        try {
          const shouldActive = !isActive;
          await togglePlanActiveService(planId, shouldActive);

          showSuccessMessage(
            `Especialidade ${shouldActive ? 'ativada' : 'inativada'} com sucesso`
          );

          await getPlans();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getPlans]
  );

  const repositionPlan = useCallback(
    async (planId: string, targetPosition: number) => {
      try {
        await repositionPlanService(planId, targetPosition);
        await getPlans();
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    },
    [getPlans]
  );

  const plansToShow = useMemo(() => {
    return (plans || []).map((plan, index) => ({
      ...plan,
      price: Number(plan.price) ? `R$ ${plan.price}` : 'Gratuito',
      actions: (
        <PlansActionButtonsContainer>
          <Button primary type="button" onClick={() => editPlan(plan.plan_id)}>
            <AiOutlineEdit />
            <span>Editar</span>
          </Button>
          <Button
            warning
            table
            type="button"
            onClick={() => togglePlanActive(plan.plan_id, plan.info.is_active)}
          >
            {plan.info.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}{' '}
            {plan.info.is_active ? 'Inativar' : 'Ativar'}
          </Button>
          <Button
            dangerFill
            type="button"
            onClick={() => removePlan(plan.plan_id)}
          >
            <BsTrashFill />
            <span>Excluir</span>
          </Button>
          <div className="arrows">
            <Button
              primary
              type="button"
              disabled={plan.position <= positions[0]}
              onClick={() => repositionPlan(plan.plan_id, plans[index - 1].position)}
            >
              <AiFillCaretUp size={17} />
            </Button>
            <Button
              primary
              type="button"
              disabled={plan.position >= positions[1]}
              onClick={() => repositionPlan(plan.plan_id, plans[index + 1].position)}
            >
              <AiFillCaretDown size={17} />
            </Button>
          </div>
        </PlansActionButtonsContainer>
      ),
    }));
  }, [
    plans,
    positions,
    editPlan,
    togglePlanActive,
    removePlan,
    repositionPlan,
  ]);

  return (
    <PlansContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Planos</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Personalização</strong>,
            <span>Planos</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <CreateAndSearchContainer>
        <Button type="button" success onClick={createPlan}>
          Criar novo plano
        </Button>
      </CreateAndSearchContainer>

      <TableAndTitleContainer>
        <PageTitle dark medium>
          Planos
        </PageTitle>
        <Table
          headerConfigs={[
            {
              label: 'Nome',
              propName: 'name',
            },
            {
              label: 'Descrição',
              propName: 'description',
            },
            {
              label: 'Preço',
              propName: 'price',
            },
            {
              label: 'Ações',
              propName: 'actions',
              attributes: { style: { width: '355px' } },
            },
          ]}
          items={plansToShow}
          itemsPerPage={10}
        />
      </TableAndTitleContainer>
    </PlansContainer>
  );
};

export default Plans;
