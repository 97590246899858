import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import home from 'src/assets/home.svg';
import FeatureCard from 'src/layouts/AppLayout/components/FeatureCard';
import Button from 'src/components/Button';
import { StatisticsContainer, StatisticsFeatureList } from './style';

const Statistics: React.FC = () => {
  const history = useHistory();

  const goTo = (address: string) => {
    history.push(address);
  };

  return (
    <StatisticsContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Estatística</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <strong>Estatística</strong>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <StatisticsFeatureList>
      <FeatureCard>
          <span className="title">Conteúdos</span>

          <span className="description">
            Gere um relatório com as estatísticas de conteúdos
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/statistics/contents')}
          >
            Ver mais
          </Button>
        </FeatureCard>
        <FeatureCard>
          <span className="title">Autores</span>

          <span className="description">
            Gere um relatório com as estatísticas de autores
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/statistics/authors')}
          >
            Ver mais
          </Button>
        </FeatureCard>
        <FeatureCard>
          <span className="title">Especialidades</span>

          <span className="description">
            Gere um relatório com as estatísticas de especialidades
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/statistics/specialties')}
          >
            Ver mais
          </Button>
        </FeatureCard>
        <FeatureCard>
          <span className="title">Temas</span>

          <span className="description">
            Gere um relatório com as estatísticas de temas
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/statistics/themes')}
          >
            Ver mais
          </Button>
        </FeatureCard>
        <FeatureCard>
          <span className="title">Tags</span>

          <span className="description">
            Gere um relatório com as estatísticas de tags
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/statistics/tags')}
          >
            Ver mais
          </Button>
        </FeatureCard>
      </StatisticsFeatureList>
    </StatisticsContainer>
  );
};

export default Statistics;
