import httpClient from "src/http-client";

export interface ITag {
  tag_id: string;
  title: string;
  description?: string;
  is_active: boolean;
  images: Record<string, any>;
  info: Record<string, any>;
  created_at: Date;
  update_at: Date;
}

export interface ICreateTagDTO {
  title: string;
  description?: string;
  images: object;
  info: object;
}

export interface IUpdateTagDTO {
  title: string;
  description?: string;
  images: object;
  info: object;
}

export const createTagService = async (data: ICreateTagDTO) => {
  return await httpClient.post<ITag>("/tags", data);
}

export const listTagsService = async () => {
  return await httpClient.get<ITag[]>("/tags");
}

export const updateTagStatusService = async (id: string, status: boolean) => {
  return await httpClient.patch<ITag>(`/tags/${id}/active`, { is_active: status });
}

export const updateTagService = async (id: string, updatedData: IUpdateTagDTO) => {
  return await httpClient.put<ITag>(`/tags/${id}`, updatedData);
}

export const deleteTagService = async (id: string) => {
  return await httpClient.delete(`/tags/${id}`);
}

export const getTagService = async (id: string) => {
  return await httpClient.get<ITag>(`/tags/${id}`);
}
