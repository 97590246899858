import styled from 'styled-components';
import Button from '../Button';

const CheckboxContainer = styled(Button)`
  padding: 0px;
  width: 16px;
  height: 16px;
  box-shadow: 0px 0px 3px 1px var(--gray);
  border-radius: 2px;

  :hover {
    box-shadow: 0px 0px 10px 1px var(--gray);
  }
`;

export { CheckboxContainer };
