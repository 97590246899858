import React, { useCallback, useEffect, useState } from 'react';
import { AiFillFileExcel, AiOutlineFilePdf } from 'react-icons/ai';
import {
  FilterForm,
  FilterFormGroup,
  FilterFormInputGroup,
  FilterFormButtonGroup,
} from 'src/layouts/AppLayout/components/Filters';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import firstDayInPreviousMonth from 'src/helpers/first-day-previous-month';
import lastDayOnNextMonth from 'src/helpers/last-day-next-month';
import EngagementFilters from 'src/models/engagement-filters';
import { EngagementContainer } from './style';
import { getEngagementReport } from 'src/services/reports';
import download from 'downloadjs';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import { Link } from 'react-router-dom';
import home from 'src/assets/home.svg';
import showErrorMessage from 'src/helpers/show-error-message';
import SystemError from 'src/models/error';
import Multiselect from 'src/components/MultiSelect';
import { getContents } from 'src/services/contents';
import Content from 'src/models/content';
import useLoading from 'src/hooks/useLoading';
import useApp from 'src/hooks/useApp';

const Engagement: React.FC = () => {
  const [params, setParams] = useState({
    start_at_gt: firstDayInPreviousMonth(),
    start_at_lt: lastDayOnNextMonth(),
    finish_at_gt: firstDayInPreviousMonth(),
    finish_at_lt: lastDayOnNextMonth(),
  } as EngagementFilters);

  const { setIsLoading } = useLoading();

  const [selectedContents, setSelectedContents] = useState<Content[]>([]);
  const [contents, setContents] = useState<Content[] | null>(null);
  const { selectedPartner } = useApp();

  const handleChangeParams = (propName: string, newValue: string) => {
    setParams({ ...params, [propName]: newValue });
  };

  const validate = useCallback(() => {
    if (!selectedContents.length) {
      throw new Error('Selecione um ou mais conteúdos à incluir no relatório');
    }

    if (
      !params.start_at_gt ||
      !params.start_at_lt ||
      !params.finish_at_gt ||
      !params.finish_at_lt
    ) {
      throw new Error('Informe todas as datas para geração do relatório!');
    }
  }, [
    params.finish_at_gt,
    params.finish_at_lt,
    params.start_at_gt,
    params.start_at_lt,
    selectedContents.length,
  ]);

  const downloadReport = async (type: string) => {
    try {
      validate();
      setIsLoading(true);
      const report = await getEngagementReport({
        ...params,
        format: type,
        content_ids: selectedContents.map((content) => content.content_id),
      });

      download(
        report,
        `Engajamento_${new Intl.DateTimeFormat('pt-BR', {
          dateStyle: 'short',
          timeStyle: 'short',
        }).format(new Date())}.${type}`
      );
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getContents({
      segmentation_item_ids: [
        selectedPartner?.segmentation_item_id ||
          process.env.REACT_APP_MANOLE_UUID ||
          '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
      ],
    }).then(({ data: contents }) => {
      setContents(
        contents?.map((content, idx) => ({
          ...content,
          name: `${idx + 1} - ${content.name}`,
        }))
      );
    });
  }, [selectedPartner?.segmentation_item_id]);

  return (
    <EngagementContainer>
      <TitleAndBreadcrumbContainer>
        <PageTitle>Conteúdos</PageTitle>
        <Breadcrumb
          crumbs={[
            <Link to="/home">
              <img src={home} alt="home" />
            </Link>,
            <Link to="/others/reports">Relatórios</Link>,
            <span>Engajamento</span>,
          ]}
        />
      </TitleAndBreadcrumbContainer>

      <FilterForm>
        <FilterFormInputGroup>
          <label htmlFor="contents" id="contents">
            Conteúdos
          </label>
          <Multiselect
            id="contents"
            placeholder="Selecione os conteúdos"
            displayValue="name"
            options={contents ?? []}
            loading={!contents}
            selectedValues={selectedContents}
            onChange={(selectedList: any) => setSelectedContents(selectedList)}
          />
        </FilterFormInputGroup>
        <FilterFormGroup columns={2}>
          <FilterFormInputGroup>
            <label htmlFor="start_at_gt">Inicio em</label>
            <Input
              type="date"
              value={params.start_at_gt}
              max={params.start_at_lt}
              onChange={(e) =>
                handleChangeParams('start_at_gt', e.target.value)
              }
            />
          </FilterFormInputGroup>

          <FilterFormInputGroup>
            <label htmlFor="start_at_lt">Até</label>
            <Input
              type="date"
              value={params.start_at_lt}
              min={params.start_at_gt}
              onChange={(e) =>
                handleChangeParams('start_at_lt', e.target.value)
              }
            />
          </FilterFormInputGroup>
        </FilterFormGroup>

        <FilterFormGroup columns={2}>
          <FilterFormInputGroup>
            <label htmlFor="finish_at_gt">Fim em</label>
            <Input
              type="date"
              value={params.finish_at_gt}
              max={params.finish_at_lt}
              onChange={(e) =>
                handleChangeParams('finish_at_gt', e.target.value)
              }
            />
          </FilterFormInputGroup>

          <FilterFormInputGroup>
            <label htmlFor="finish_at_lt">Até</label>
            <Input
              type="date"
              value={params.finish_at_lt}
              min={params.finish_at_gt}
              onChange={(e) =>
                handleChangeParams('finish_at_lt', e.target.value)
              }
            />
          </FilterFormInputGroup>
        </FilterFormGroup>

        <FilterFormButtonGroup>
          <Button primary type="button" onClick={() => downloadReport('pdf')}>
            <AiOutlineFilePdf size={16} />
            <span>Relatório (PDF)</span>
          </Button>
          <Button primary type="button" onClick={() => downloadReport('csv')}>
            <AiFillFileExcel size={16} />
            <span>Relatório (CSV)</span>
          </Button>
        </FilterFormButtonGroup>
      </FilterForm>
    </EngagementContainer>
  );
};

export default Engagement;
