import React from 'react';
import {
  // LoginLayoutContact,
  LoginLayoutContainer,
  LoginLayoutContent,
  LoginLayoutCopy,
  LoginLayoutFooter,
  LoginLayoutHeader,
  LoginLayoutLogo,
} from './style';
import LoginBackImage from 'src/assets/login-back-image.png';
import CustomerLogo from 'src/assets/logos/logo-manole-play-white.svg';

const LoginLayout: React.FC = ({ children }) => {
  return (
    <LoginLayoutContainer background={LoginBackImage}>
      <LoginLayoutHeader className="max-content">
        <LoginLayoutLogo src={CustomerLogo} alt="customer logo" />

        {/* <LoginLayoutContact>Fale Conosco</LoginLayoutContact> */}
      </LoginLayoutHeader>
      <LoginLayoutContent className="max-content">
        {children}
      </LoginLayoutContent>
      <LoginLayoutFooter className="max-content">
        <LoginLayoutCopy>&copy; 2021 Admin LMS2</LoginLayoutCopy>
      </LoginLayoutFooter>
    </LoginLayoutContainer>
  );
};

export default LoginLayout;
