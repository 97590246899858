import React, { useContext } from 'react';
import Button from 'src/components/Button';
import FeatureCard from 'src/layouts/AppLayout/components/FeatureCard';
import { AdminFeatureList, HomeContainer, HomeGreetings } from './style';
import AuthContext from 'src/contexts/AuthContext';
import { useHistory } from 'react-router';

const Home: React.FC = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const goTo = (address: string) => {
    history.push(address);
  };

  return (
    <HomeContainer>
      <HomeGreetings>
        <span className="hello">Olá, {user?.name || 'Desconhecido'}</span>
        <span className="greetings">
          Seja bem-vindo ao painel de controle de seu LMS.
        </span>
      </HomeGreetings>

      <AdminFeatureList>
        <FeatureCard>
          <span className="title">Acadêmico</span>

          <span className="description">
            Gerencie as Especialidades, temas, conteúdos e autores da plataforma
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/customization/academic')}
          >
            Ver mais
          </Button>
        </FeatureCard>

        {/* <FeatureCard>
          <span className="title">Avaliações</span>

          <span className="description">
            Avalie seus alunos e veja o quanto eles estão aprendendo!
          </span>

          <Button type="button" className="view-more">
            Ver mais
          </Button>
        </FeatureCard>

        <FeatureCard>
          <span className="title">Depoimentos</span>

          <span className="description">
            Veja os depoimentos e destaque os melhores!
          </span>

          <Button type="button" className="view-more">
            Ver mais
          </Button>
        </FeatureCard> */}

        <FeatureCard>
          <span className="title">Relatórios</span>

          <span className="description">
            Acesse agora os relatórios dos seus conteúdos.
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/reports')}
          >
            Ver mais
          </Button>
        </FeatureCard>

        <FeatureCard>
          <span className="title">Estatísticas</span>

          <span className="description">
            Acesse agora as estatísticas dos seus conteúdos.
          </span>

          <Button
            type="button"
            className="view-more"
            onClick={() => goTo('/others/statistics')}
          >
            Ver mais
          </Button>
        </FeatureCard>

        {/* <FeatureCard>
          <span className="title">Depoimentos</span>

          <span className="description">
            Organize a interação entre os estudantes por aqui.
          </span>

          <Button type="button" className="view-more">
            Ver mais
          </Button>
        </FeatureCard>

        <FeatureCard>
          <span className="title">FAQs</span>

          <span className="description">
            Responda as perguntas mais frequentes realizadas pelos alunos sobre
            a plataforma!
          </span>

          <Button type="button" className="view-more">
            Ver mais
          </Button>
        </FeatureCard> */}
      </AdminFeatureList>
    </HomeContainer>
  );
};

export default Home;
