import React, { useContext } from 'react';
import SelectPartner from '../../../SelectPartner';
import {
  HeaderUserContainer,
  ToggleUserMenu,
  UserAvatar,
  UserContextMenuContainer,
  UserContextMenuItem,
  UserName,
} from './style';
import AppContext from 'src/contexts/AppContext';
import AuthContext from 'src/contexts/AuthContext';
import useAuth from 'src/hooks/useAuth';
import useModal from 'src/hooks/useModal';
import defaultUserImage from 'src/assets/default-user-image.png';

const UserContextMenu: React.FC = () => {
  const { signOut } = useAuth()
  const { showModal } = useModal();

  const changePartner = () => {
    showModal('Selecione um Parceiro para Administrar', <SelectPartner />);
  };

  return (
    <UserContextMenuContainer>
      <UserContextMenuItem onClick={changePartner}>
        Administrar Parceiro
      </UserContextMenuItem>
      <UserContextMenuItem onClick={signOut}>Sair</UserContextMenuItem>
    </UserContextMenuContainer>
  );
};

const HeaderUser: React.FC = () => {
  const { showUserContextMenu, setShowUserContextMenu, selectedPartner } = useContext(AppContext);
  const { user } = useContext(AuthContext);

  const toogleShowUserContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();

    setShowUserContextMenu((show) => !show);
  };

  return (
    <HeaderUserContainer>
      <ToggleUserMenu onClick={toogleShowUserContextMenu}>
        <UserAvatar alt="avatar" src={user?.images?.avatar || defaultUserImage} />
        <UserName>
          {user?.name || 'Desconhecido'}{' '}
          {!!selectedPartner?.name && `(${selectedPartner.name})`}
        </UserName>
      </ToggleUserMenu>
      {showUserContextMenu && <UserContextMenu />}
    </HeaderUserContainer>
  );
};

export default HeaderUser;
