export function validateBirthDate(birthDate: string, minimumAge: number): boolean {
  if (birthDate.length !== 10) return false

  let [year, month, day] = birthDate.split('-')
  year = String(Number(year) + minimumAge)

  const date = new Date(`${year}-${month}-${day}`)
  const dateToday = new Date()

  return date <= dateToday
}
