import styled from 'styled-components';

const PlansContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const PlansActionButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  button {
    :nth-last-child(n + 3) {
      min-width: 100px !important;
      max-width: 140px !important;
      padding: 8px;
    }
  }

  .arrows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2px;

    button {
      max-height: 15px !important;
      padding: 0 6px;
    }
  }
`;

export { PlansContainer, PlansActionButtonsContainer };
