import httpClient from 'src/http-client';
import Content from 'src/models/content';
import Paginated from 'src/models/paginated';

const getContents = async (filters?: any) => {
  const { data } = await httpClient.get<Paginated<Content>>(`/contents`, {
    params: { ...filters, flag: ['content'], relations: ['segmentations'] },
  })
  return data;
};

const getContent = async (contentId: string) => {
  const { data } = await httpClient.get<Content>(`/contents/${contentId}`, {
    params: {
      relations: ['authors', 'extra_materials', 'tags'],
      admin: true,
    },
  })
  return data;
};

const createContent = async (newContent: Partial<Content>) => {
  const { data } = await httpClient.post(`/contents`, { ...newContent, flag: 'content' })
  return data;
};

const updateContent = async (contentId: string, newContent: Partial<Content>) => {
  delete newContent.content_id;
  delete newContent.created_at;
  delete newContent.updated_at;
  delete newContent.segmentation_items;

  if (newContent.authors?.length) {
    newContent.authors = (newContent.authors || []).map((a) => ({
      author_id: a.author_id,
    }));
  }

  if(newContent.tags?.length){
    // @ts-ignore
    newContent.tags = newContent.tags?.map(({ tag_id}) => ({ tag_id })) || [];
  }

  const { data } = await httpClient.put(`/contents/${contentId}`, {
    ...newContent,
    flag: 'content',
  })

  return data;
};

const deleteContent = async (contentId: string) => {
  await httpClient.delete(`/contents/${contentId}`);
};

const toggleContentActive = async (contentId: string, shouldActive: boolean) => {
  const { data } = await httpClient.patch(`/contents/${contentId}/active`, {
    is_active: shouldActive,
  })
  return data;
};

const toggleContentHighlight = async (contentId: string) => {
  const content = (await getContent(contentId)) as Partial<Content>;

  content.is_highlight = !content.is_highlight;
  delete content.extra_materials;

  return updateContent(contentId, content);
};

export {
  getContents,
  getContent,
  createContent,
  updateContent,
  deleteContent,
  toggleContentActive,
  toggleContentHighlight,
};
