import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import CutImage from 'src/layouts/AppLayout/components/CutImage';
import TextArea from 'src/layouts/AppLayout/components/TextArea';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import ThumbnailContainer from 'src/layouts/AppLayout/components/ThumbnailContainer';
import TitleAndTagContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import {
  CreationForm,
  CreationFormButtonGroup,
  CreationFormGroup,
  CreationFormImageGroup,
} from 'src/layouts/AppLayout/components/Forms';
import { CreateAndEditTagContainer } from './style';
import {
  createTagService,
  updateTagService,
  getTagService,
  ITag,
} from 'src/services/tags';
import { uploadFile } from 'src/services/files';
import useModal from 'src/hooks/useModal';
import useLoading from 'src/hooks/useLoading';
import SystemError from 'src/models/error';
import showErrorMessage from 'src/helpers/show-error-message';
import checkEmptyString from 'src/helpers/check-empty-string';
import showSuccessMessage from 'src/helpers/show-success-message';
import home from 'src/assets/home.svg';

interface CreateAndEditTagParams {
  tagId?: string;
}

interface CreateAndEditTagProps {
  modalId?: string;
  tagId?: string;
  onUpdateOrSave?: () => void;
}

const CreateAndEditTag: React.FC<CreateAndEditTagProps> = ({
  modalId,
  tagId: propsTagId,
  onUpdateOrSave,
}) => {
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { hideModal, showModal } = useModal();
  const { tagId: paramsTagId } = useParams<CreateAndEditTagParams>();
  const [tag, setTag] = useState({} as Omit<ITag, "tag_id">);

  const tagId = useMemo(() => {
    return propsTagId || paramsTagId;
  }, [propsTagId, paramsTagId]);

  const handleChangeTag = (propName: string, value: any) => {
    setTag((tag) => {
      return { ...tag, [propName]: value };
    });
  };

  const validate = () => {
    if (checkEmptyString(tag.title)) {
      throw new Error('Informe um nome para a tag');
    }

    if (checkEmptyString(tag.description)) {
      throw new Error('Informe uma description para a tag');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      tag.description = tag.description || '';

      if (isEditing) {
        const { title, description, info, images } = tag;

        await updateTagService(tagId!, {
          title,
          description,
          info,
          images,
        });
        showSuccessMessage('Tag editada com sucesso!');
      } else {
        await createTagService(tag);
        showSuccessMessage('Tag cadastrada com sucesso!');
      }

      goToTag();
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  };

  const getTag = useCallback(async () => {
    if (tagId) {
      setIsLoading(true);

      const {
        data: { tag_id, ...tag },
      } = await getTagService(tagId);
      setTag(tag);

      setIsLoading(false);
    }
  }, [tagId, setIsLoading]);

  const goToTag = () => {
    if (modalId) {
      onUpdateOrSave && onUpdateOrSave();
      hideModal(modalId);
    } else {
      history.push('/customization/academic/tags');
    }
  };

  const cutImage = () => {
    showModal(
      'Cortar imagem',
      <CutImage onCutImage={uploadAndSelectImage} aspect={1.67} />
    );
  };

  const uploadAndSelectImage = async (image: File) => {
    setIsLoading(true);

    try {
      const { reference } = await uploadFile(image);
      handleChangeTag('images', {
        ...tag.images,
        thumbnail: reference,
      });
    } catch (e) {
      showErrorMessage(e as SystemError);
    }

    setIsLoading(false);
  };

  const removeThumb = () => {
    setTag((content) => ({
      ...(content || {}),
      images: { thumbnail: '' },
    }));
  };

  const isEditing = useMemo(() => {
    return !!tagId;
  }, [tagId]);

  useEffect(() => {
    getTag();
  }, [getTag]);

  return (
    <CreateAndEditTagContainer isOnModal={!!modalId}>
      {!modalId ? (
        <TitleAndBreadcrumbContainer>
          <PageTitle>{isEditing ? 'Editar' : 'Criar'} Tag</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/academic">Acadêmico</Link>,
              <Link to="/customization/academic/tags">Tags</Link>,
              <span>{isEditing ? 'Editar' : 'Criar'} Tag</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      ) : (
        <></>
      )}

      <TitleAndTagContainer>
        <PageTitle dark medium>
          {isEditing ? 'Editar' : 'Criar'} Tag
        </PageTitle>

        <CreationForm onSubmit={handleSubmit}>
          <CreationFormGroup>
            <label htmlFor="title" className="required">
              Nome
            </label>
            <Input
              id="title"
              placeholder="Nome"
              value={tag.title}
              onChange={(e) => handleChangeTag('title', e.target.value)}
              required
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="description" className='required'>Descrição</label>
            <TextArea
              id="description"
              required
              placeholder="Descrição"
              value={tag.description}
              onChange={(e) => handleChangeTag('description', e.target.value)}
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="select-image">
              Thumbnail
            </label>
            <CreationFormImageGroup>
              {tag?.images?.thumbnail ? (
                <ThumbnailContainer
                  src={tag?.images?.thumbnail}
                  removeThumb={removeThumb}
                />
              ) : (
                <Button type="button" id="select-image" onClick={cutImage}>
                  Selecione a imagem para o thumbnail da tag
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormButtonGroup>
            <Button danger type="button" onClick={goToTag}>
              Cancelar
            </Button>
            <Button primary>Salvar tag</Button>
          </CreationFormButtonGroup>
        </CreationForm>
      </TitleAndTagContainer>
    </CreateAndEditTagContainer>
  );
};

export default CreateAndEditTag;
