import FeatureList from 'src/layouts/AppLayout/components/FeatureList';
import styled from 'styled-components';

const HomeContainer = styled.div`
  width: 100%;
`;

const HomeGreetings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--white);
  margin-bottom: 48px;

  & .hello {
    font-size: 2rem;
    font-weight: bold;
  }

  & .greetings {
    font-size: 1rem;
    font-weight: 200;
  }
`;

const AdminFeatureList = styled(FeatureList)``;

export { HomeContainer, HomeGreetings, AdminFeatureList };
