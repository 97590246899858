import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Switch from 'react-switch';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import {
  AuthorContainer,
  TagsContainer,
  TagItem,
  CreateAndEditContentsContainer,
  ExtraMaterialCardContainer,
  ExtraMaterials,
} from './style';
import home from 'src/assets/home.svg';
import {
  CreationForm,
  CreationFormButtonGroup,
  CreationFormGroup,
  CreationFormImageGroup,
  CreationFormSwitch,
} from 'src/layouts/AppLayout/components/Forms';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import TitleAndContentContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import ModalContext from 'src/contexts/ModalContext';
import CutImage from 'src/layouts/AppLayout/components/CutImage';
import { uploadFile } from 'src/services/files';
import showErrorMessage from 'src/helpers/show-error-message';
import SelectOrUploadMedia from 'src/components/SelectOrUploadMedia';
import Content from 'src/models/content';
import ThumbnailContainer from 'src/layouts/AppLayout/components/ThumbnailContainer';
import VideoContainer from 'src/layouts/AppLayout/components/VideoContainer';
import checkEmptyString from 'src/helpers/check-empty-string';
import SystemError from 'src/models/error';
import showSuccessMessage from 'src/helpers/show-success-message';
import {
  createContent as createContentService,
  updateContent as updateContentService,
  getContent as getContentService,
} from 'src/services/contents';
import { createExtraMaterial as createExtraMaterialService } from 'src/services/extra-materials';
import useLoading from 'src/hooks/useLoading';
import Authors from '../../../Authors';
import Author from 'src/models/author';
import { AiOutlineClose } from 'react-icons/ai';
import ExtraMaterial from 'src/models/extra-material';
import AppContext from 'src/contexts/AppContext';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import 'suneditor/dist/css/suneditor.min.css';
import { ITag } from 'src/services/tags';
import Tags from 'src/pages/Academic/pages/Tags';

interface ExtraMaterialCardProps {
  extraMaterial: ExtraMaterial;
  remove: (extraMaterialId: string) => void;
}

const ExtraMaterialCard: React.FC<ExtraMaterialCardProps> = ({
  extraMaterial,
  remove,
}) => {
  return (
    <ExtraMaterialCardContainer>
      <a
        download
        target="_blank"
        rel="noreferrer"
        href={extraMaterial.reference}
      >
        {extraMaterial.title}
      </a>
      <Button
        type="button"
        onClick={() => remove(extraMaterial.extra_material_id)}
        circle
        dangerFill
        small
        className="close-button"
      >
        <AiOutlineClose />
      </Button>
    </ExtraMaterialCardContainer>
  );
};

interface CreateAndEditContentsParams {
  contentId?: string;
}

interface CreateAndEditContentsProps {
  onUpdateOrSave?: () => void;
  contentId?: string;
  modalId?: string;
}

const CreateAndEditContents: React.FC<CreateAndEditContentsProps> = ({
  onUpdateOrSave,
  contentId: propsContentId,
  modalId,
}) => {
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { contentId: paramsContentId } =
    useParams<CreateAndEditContentsParams>();
  const extraMaterialInput = useRef<HTMLInputElement>(null);

  const { showModal, hideModal } = useContext(ModalContext);
  const { selectedPartner } = useContext(AppContext);

  const [content, setContent] = useState({
    info: { is_free: false },
  } as Content);
  const [editor, setEditor] = useState<SunEditorCore>();

  const getSunEditorInstance = useCallback((sunEditor: SunEditorCore) => {
    setEditor(sunEditor);
  }, []);

  const contentId = useMemo(() => {
    return propsContentId || paramsContentId;
  }, [propsContentId, paramsContentId]);

  const handleChangeContent = (propName: string, value: any) => {
    setContent((content) => {
      return { ...content, [propName]: value };
    });
  };

  const handleChangeContentIsFree = (is_free: boolean) => {
    setContent((content) => {
      return {
        ...content,
        info: {
          is_free,
        },
      };
    });
  };

  const validate = () => {
    if (checkEmptyString(content.name)) {
      throw new Error('Informe um nome para o conteúdo');
    }

    if (checkEmptyString(content.description)) {
      throw new Error('Informe uma descrição para o conteúdo');
    }

    if (!content?.images?.thumbnail) {
      throw new Error('Informe uma imagem de capa para o conteúdo');
    }

    if (!content.reference) {
      throw new Error('Informe um vídeo para o conteúdo');
    }

    if (!content.authors?.length) {
      throw new Error('Informe um autor para o conteúdo');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      content.description = editor ? editor?.getContents(false) : '';

      validate();

      if (content.authors?.length) {
        content.authors = content.authors?.map((author) => ({
          author_id: author.author_id,
        }));
      }

      if (content.extra_materials?.length) {
        content.extra_materials = content.extra_materials.map((em) => ({
          extra_material_id: em.extra_material_id,
        }));
      }

      if (selectedPartner?.segmentation_item_id) {
        content.segmentation_items = [
          {
            segmentation_item_id:
              selectedPartner.segmentation_item_id ||
              process.env.REACT_APP_MANOLE_UUID ||
              '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
          },
        ];
      }

      if (content.tags?.length) {
        // @ts-ignore
        content.tags = content.tags.map(({ tag_id }) => ({ tag_id }));
      }

      if (isEditing) {
        await updateContentService(contentId!, content);
        showSuccessMessage('Conteúdo editado com sucesso!');
      } else {
        await createContentService(content);
        showSuccessMessage('Conteúdo cadastrado com sucesso!');
      }

      goToContents();
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  };

  const getContent = useCallback(async () => {
    if (contentId) {
      setIsLoading(true);

      const content = await getContentService(contentId);
      setContent(content);

      setIsLoading(false);
    }
  }, [contentId, setIsLoading]);

  const cutImage = () => {
    showModal(
      'Cortar imagem',
      <CutImage onCutImage={uploadAndSelectImage} aspect={1.67} />
    );
  };

  const uploadAndSelectImage = async (image: File) => {
    setIsLoading(true);

    try {
      const { reference } = await uploadFile(image);
      handleChangeContent('images', {
        ...content.images,
        thumbnail: reference,
      });
    } catch (e) {
      showErrorMessage(e as SystemError);
    }

    setIsLoading(false);
  };

  const removeThumb = () => {
    setContent((content) => ({
      ...(content || {}),
      images: { thumbnail: '' },
    }));
  };

  const removeVideo = () => {
    setContent((content) => ({ ...(content || {}), reference: '' }));
  };

  const selectMedia = () => {
    showModal(
      'Selecionar Vídeo',
      <SelectOrUploadMedia
        onSelectContent={({ url, duration }) => {
          handleChangeContent('reference', url);
          handleChangeContent('duration', duration);
        }}
      />
    );
  };

  const selectAuthor = () => {
    showModal('Selecionar autor', <Authors onSelectAuthor={onSelectAuthor} />);
  };

  const onSelectAuthor = (author: Author) => {
    handleChangeContent('authors', [
      { author_id: author.author_id, name: author.name },
    ]);
  };

  const removeAuthor = () => {
    handleChangeContent('authors', []);
  };

  const selectTags = () => {
    showModal(
      'Selecionar tags',
      <Tags
        onSelectedTags={onSelectTag}
        alreadySelectedTags={content.tags || []}
      />
    );
  };

  const onSelectTag = (tags: ITag[]) => {
    handleChangeContent(
      'tags',
      tags.map(({ tag_id, title }) => ({ tag_id, title }))
    );
  };

  const removeTag = (tag: ITag) => {
    const tags = content.tags?.filter((tg) => tg.tag_id !== tag.tag_id);
    handleChangeContent('tags', tags);
  };

  const goToContents = () => {
    if (modalId) {
      onUpdateOrSave && onUpdateOrSave();
      hideModal(modalId);
    } else {
      history.push('/customization/academic/contents');
    }
  };

  const addExtraMaterial = () => {
    extraMaterialInput.current?.click();
  };

  const handleExtraMaterialInputChange = async (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);

    try {
      const file = (extraMaterialInput.current?.files || [])[0];
      if (file) {
        const { reference } = await uploadFile(file);

        const extraMaterial = await createExtraMaterialService({
          title: file.name,
          reference,
        });

        handleChangeContent('extra_materials', [
          ...(content.extra_materials || []),
          extraMaterial,
        ]);

        extraMaterialInput.current!.value = '';
      }
    } catch (error) {
      showErrorMessage(error as SystemError);
    }

    setIsLoading(false);
  };

  const removeExtraMaterial = (extraMaterialId: string) => {
    handleChangeContent(
      'extra_materials',
      (content.extra_materials || []).filter(
        (em) => em.extra_material_id !== extraMaterialId
      )
    );
  };

  const isEditing = useMemo(() => {
    return !!contentId;
  }, [contentId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  useEffect(() => {
    if (!!editor && !!editor.setContents && !!content.description) {
      editor.setContents(content.description);
    }
  }, [content.description, editor]);

  return (
    <CreateAndEditContentsContainer isOnModal={!!modalId}>
      {!modalId && (
        <TitleAndBreadcrumbContainer>
          <PageTitle>{isEditing ? 'Editar' : 'Criar'} Conteúdo</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/academic">Acadêmico</Link>,
              <Link to="/customization/academic/contents">Conteúdos</Link>,
              <span>{isEditing ? 'Editar' : 'Criar'} Conteúdo</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      )}

      <TitleAndContentContainer>
        <PageTitle dark medium>
          {isEditing ? 'Editar' : 'Criar'} Conteúdo
        </PageTitle>

        <CreationForm onSubmit={handleSubmit}>
          <div className="flex">
            <CreationFormGroup>
              <label htmlFor="name" className="required">
                Nome
              </label>
              <Input
                id="name"
                placeholder="Nome"
                value={content.name}
                onChange={(e) => handleChangeContent('name', e.target.value)}
                required
              />
            </CreationFormGroup>

            <CreationFormSwitch>
              <label className="required">Conteúdo gratuito:</label>
              <Switch
                onChange={handleChangeContentIsFree}
                checked={!!content.info?.is_free}
              />
            </CreationFormSwitch>
          </div>

          <CreationFormGroup>
            <label htmlFor="description" className="required">
              Descrição
            </label>
            <SunEditor
              height="160px"
              getSunEditorInstance={getSunEditorInstance}
              setOptions={{
                buttonList: [
                  [
                    'undo',
                    'redo',
                    'fontSize',
                    'bold',
                    'underline',
                    'list',
                    'removeFormat',
                    'outdent',
                    'indent',
                    'align',
                    'horizontalRule',
                    'list',
                    'link',
                  ],
                ],
                imageRotation: false,
              }}
            />
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="select-image" className="required">
              Imagem
            </label>
            <CreationFormImageGroup>
              {content?.images?.thumbnail ? (
                <ThumbnailContainer
                  src={content?.images?.thumbnail}
                  removeThumb={removeThumb}
                />
              ) : (
                <Button type="button" id="select-image" onClick={cutImage}>
                  Selecione a imagem para seu conteúdo
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="select-video" className="required">
              Vídeo
            </label>
            <CreationFormImageGroup>
              {content.reference ? (
                <VideoContainer
                  reference={content?.reference}
                  removeVideo={removeVideo}
                />
              ) : (
                <Button type="button" id="select-video" onClick={selectMedia}>
                  Selecione um vídeo para seu conteúdo
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="select-author" className="required">
              Autor
            </label>
            <CreationFormImageGroup>
              {content.authors?.length ? (
                <AuthorContainer>
                  <span>{content.authors[0].name}</span>
                  <Button
                    circle
                    dangerFill
                    small
                    type="button"
                    className="remove-button"
                    onClick={removeAuthor}
                  >
                    <AiOutlineClose />
                  </Button>
                </AuthorContainer>
              ) : (
                <Button type="button" id="select-author" onClick={selectAuthor}>
                  Selecione um autor para o seu conteúdo
                </Button>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="select-tags">
              Tags
            </label>
            <CreationFormImageGroup>
              <TagsContainer>
                {content.tags?.map((tag) => (
                  <TagItem>
                    <span>{tag.title}</span>
                    <button
                      type="button"
                      className="remove-button"
                      onClick={() => removeTag(tag)}
                    >
                      <AiOutlineClose />
                    </button>
                  </TagItem>
                ))}
              </TagsContainer>
              <Button type="button" id="select-tags" onClick={selectTags}>
                Selecione as tags para o seu conteúdo
              </Button>
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormGroup>
            <label htmlFor="extra_materials">Materiais complementares</label>

            <CreationFormImageGroup>
              <Button
                type="button"
                id="extra_materials"
                onClick={addExtraMaterial}
              >
                Selecione um novo material complementar para o seu conteúdo
              </Button>

              <input
                ref={extraMaterialInput}
                type="file"
                style={{ display: 'none' }}
                onChange={handleExtraMaterialInputChange}
              />

              {content.extra_materials?.length && (
                <ExtraMaterials>
                  {content.extra_materials?.map((em) => (
                    <ExtraMaterialCard
                      key={em.extra_material_id}
                      extraMaterial={em as ExtraMaterial}
                      remove={removeExtraMaterial}
                    />
                  ))}
                </ExtraMaterials>
              )}
            </CreationFormImageGroup>
          </CreationFormGroup>

          <CreationFormButtonGroup>
            <Button danger type="button" onClick={goToContents}>
              Cancelar
            </Button>
            <Button primary>Salvar conteúdo</Button>
          </CreationFormButtonGroup>
        </CreationForm>
      </TitleAndContentContainer>
    </CreateAndEditContentsContainer>
  );
};

export default CreateAndEditContents;
