import httpClient from 'src/http-client';

const getEngagementReport = async (params: IReportsParams) => {
  return (
    await httpClient.get(`/reports/engagement/`, {
      params,
      responseType: 'blob',
    })
  ).data;
};

type IReportsParams = {
  start_at_gt: string;
  start_at_lt: string;
  finish_at_gt: string;
  finish_at_lt: string;
  format?: string;
  content_ids: string[];
}

const getContentsFinishedReport = async (
  params: IReportsParams
) => {
  return (
    await httpClient.get(`/reports/content-finished/`, {
      params,
      responseType: 'blob',
    })
  ).data;
};

export { getEngagementReport, getContentsFinishedReport };
