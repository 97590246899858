import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginLayout from '../components/LoginLayout';
import AppLayout from 'src/layouts/AppLayout';
import Login from '../pages/login';
import Home from 'src/pages/home';
import Contents from 'src/pages/Academic/pages/Contents';
import Academic from 'src/pages/Academic';
import CreateAndEditContents from 'src/pages/Academic/pages/Contents/components/CreateAndEditContents';
import Themes from 'src/pages/Academic/pages/Themes';
import CreateAndEditThemes from 'src/pages/Academic/pages/Themes/components/CreateAndEditThemes';
import Specialties from 'src/pages/Academic/pages/Specialties';
import CreateAndEditSpecialties from 'src/pages/Academic/pages/Specialties/components/CreateAndEditSpecialties';
import Authors from 'src/pages/Academic/pages/Authors';
import CreateAndEditAuthors from 'src/pages/Academic/pages/Authors/components/CreateAndEditAuthors';
import Partners from 'src/pages/Partners';
import CreateAndEditPartners from 'src/pages/Partners/components/CreateAndEditPartners';
import Reports from 'src/pages/reports';
import Engagement from 'src/pages/reports/pages/Engagement';
import ContentsFinished from 'src/pages/reports/pages/ContentsFinished';
import Users from 'src/pages/users';
import { CreateAndEditUser } from 'src/pages/users/components/createAndEditUser';
import SearchResults from 'src/pages/SearchResults';
import Plans from 'src/pages/Plans';
import CreateAndEditPlans from 'src/pages/Plans/components/CreateAndEditPlans';
import Tags from 'src/pages/Academic/pages/Tags';
import CreateAndEditTag from "src/pages/Academic/pages/Tags/components/createAndEditTag";
import Statistics from 'src/pages/statistics';
import StatsCommonPage from 'src/pages/statistics/pages/StatsCommonPage';
import { ENTITY_TYPES } from 'src/services/statistics';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={['/', '/login', '/forgot-password', '/create-account']}
      >
        <LoginLayout>
          <Login />
        </LoginLayout>
      </Route>

      <Route exact>
        <AppLayout>
          <Switch>
            <Route path="/home" exact>
              <Home />
            </Route>

            {/* USERS */}

            <Route path="/users" exact>
              <Users />
            </Route>

            <Route path="/users/create" exact>
              <CreateAndEditUser />
            </Route>

            <Route path="/users/edit/:userId" exact>
              <CreateAndEditUser />
            </Route>

            {/* customization */}

            <Route path={['/customization']}>
              <Switch>
                <Route path={['/customization/partners']}>
                  <Route path="/customization/partners/" exact>
                    <Partners />
                  </Route>

                  <Route path="/customization/partners/create-partner" exact>
                    <CreateAndEditPartners />
                  </Route>
                  <Route
                    path="/customization/partners/edit-partner/:partnerId"
                    exact
                  >
                    <CreateAndEditPartners />
                  </Route>
                </Route>

                <Route path={['/customization/academic']}>
                  <Switch>
                    <Route path="/customization/academic/" exact>
                      <Academic />
                    </Route>

                    <Route path="/customization/academic/contents" exact>
                      <Contents />
                    </Route>
                    <Route
                      path="/customization/academic/contents/create-content"
                      exact
                    >
                      <CreateAndEditContents />
                    </Route>
                    <Route
                      path="/customization/academic/contents/edit-content/:contentId"
                      exact
                    >
                      <CreateAndEditContents />
                    </Route>

                    <Route path="/customization/academic/themes" exact>
                      <Themes />
                    </Route>
                    <Route
                      path="/customization/academic/themes/create-theme"
                      exact
                    >
                      <CreateAndEditThemes />
                    </Route>
                    <Route
                      path="/customization/academic/themes/edit-theme/:themeId"
                      exact
                    >
                      <CreateAndEditThemes />
                    </Route>

                    <Route path="/customization/academic/specialties" exact>
                      <Specialties />
                    </Route>
                    <Route
                      path="/customization/academic/specialties/create-specialty"
                      exact
                    >
                      <CreateAndEditSpecialties />
                    </Route>
                    <Route
                      path="/customization/academic/specialties/edit-specialty/:specialtyId"
                      exact
                    >
                      <CreateAndEditSpecialties />
                    </Route>

                    <Route path="/customization/academic/authors" exact>
                      <Authors />
                    </Route>
                    <Route
                      path="/customization/academic/authors/create-author"
                      exact
                    >
                      <CreateAndEditAuthors />
                    </Route>
                    <Route
                      path="/customization/academic/authors/edit-author/:authorId"
                      exact
                    >
                      <CreateAndEditAuthors />
                    </Route>

                    {/* TAGS ROUTES */}
                    <Route path="/customization/academic/tags" exact>
                      <Tags />
                    </Route>
                    <Route
                      path="/customization/academic/tags/edit-tag/:tagId"
                      exact
                    >
                      <CreateAndEditTag />
                    </Route>
                    <Route
                      path="/customization/academic/tags/create-tag"
                      exact
                    >
                      <CreateAndEditTag />
                    </Route>
                  </Switch>
                </Route>

                <Route path={['/customization/plans']}>
                  <Route path="/customization/plans/" exact>
                    <Plans />
                  </Route>

                  <Route path="/customization/plans/create-plan" exact>
                    <CreateAndEditPlans />
                  </Route>

                  <Route path="/customization/plans/edit-plan/:planId" exact>
                    <CreateAndEditPlans />
                  </Route>
                </Route>
              </Switch>
            </Route>

            <Route path={['/others']}>
              <Switch>
                <Route path={['/others/reports']}>
                  <Switch>
                    <Route path="/others/reports/" exact>
                      <Reports />
                    </Route>

                    <Route path="/others/reports/engagement" exact>
                      <Engagement />
                    </Route>

                    <Route path="/others/reports/contents-finished" exact>
                      <ContentsFinished />
                    </Route>
                  </Switch>
                </Route>
                <Route path={['/others/statistics']}>
                  <Switch>
                    <Route path="/others/statistics/" exact>
                      <Statistics />
                    </Route>

                    <Route path="/others/statistics/contents" exact>
                      <StatsCommonPage pageName='Contéudos' entityName='contéudos' type={ENTITY_TYPES.CONTENT} />
                    </Route>

                    <Route path="/others/statistics/authors" exact>
                      <StatsCommonPage pageName='Autores' entityName='autores' type={ENTITY_TYPES.AUTHOR} />
                    </Route>

                    <Route path="/others/statistics/specialties" exact>
                      <StatsCommonPage pageName='especialidades' entityName='especialidades' type={ENTITY_TYPES.SPECIALTY} />
                    </Route>

                    <Route path="/others/statistics/themes" exact>
                      <StatsCommonPage pageName='Temas' entityName='temas' type={ENTITY_TYPES.THEME} />
                    </Route>

                    <Route path="/others/statistics/tags" exact>
                      <StatsCommonPage pageName='tags' entityName='tags' type={ENTITY_TYPES.TAG} />
                    </Route>
                  </Switch>
                </Route>
              </Switch>
            </Route>

            <Route path="/search/:query" exact>
              <SearchResults />
            </Route>
          </Switch>
        </AppLayout>
      </Route>
    </Switch>
  );
};

export default Routes;
