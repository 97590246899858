import styled, { css } from 'styled-components';

interface CreateAndEditContentsContainerProps {
  isOnModal: boolean;
}

const CreateAndEditContentsContainer = styled.div<CreateAndEditContentsContainerProps>`
  width: 100%;

  ${(props) =>
    props.isOnModal &&
    css`
      width: 80vw;
      height: 75vh;
      overflow-y: auto;
    `}

  .sun-editor {
    z-index: 0;
  }
`;

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  position: relative;
  border: dashed 1px var(--border-gray);
  border-radius: 4px;
  padding: 8px;

  & > .remove-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(35%, -35%);
  }
`;

const TagsContainer = styled.ul`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  padding: 8px;
`;

const TagItem = styled.li`
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px 6px;
  border: dashed 1px var(--border-gray);
  border-radius: 4px;
  color: var(--primary);

  & > .remove-button {
    position: absolute;
    top: -12px;
    right: -12px;

    width: 20px;
    height: 20px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #fff;
    background: var(--danger);

    border: solid 1px var(--danger);
    transition: .1s all;

    & > svg {
      width: 10px;
      height: 10px;
      color: inherit;
    }

    :hover {
      cursor: pointer;
      box-shadow: 0 0 3px 1px var(--danger);
      transform: scale(1.2);
    }
  }
`;

const ExtraMaterialCardContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: dashed 1px var(--border-gray);
  border-radius: 4px;

  a {
    text-decoration: none;
    text-align: left;
    font-size: 1em;
    width: 100%;
    transition: all 0.5s;
    padding: 8px;
    border-radius: 4px;
    color: var(--text-black);

    :hover {
      background: var(--primary-40);
      color: var(--white);
    }
  }

  button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(35%, -35%);
  }
`;

const ExtraMaterials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
`;

export {
  CreateAndEditContentsContainer,
  AuthorContainer,
  TagsContainer,
  TagItem,
  ExtraMaterialCardContainer,
  ExtraMaterials,
};
