import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/layouts/AppLayout/components/Breadcrumb';
import CreateAndSearchContainer from 'src/layouts/AppLayout/components/CreateAndSearchContainer';
import PageTitle from 'src/layouts/AppLayout/components/PageTitle';
import Table from 'src/layouts/AppLayout/components/Table';
import TitleAndBreadcrumbContainer from 'src/layouts/AppLayout/components/TitleAndBreadcrumbContainer';
import Button from 'src/components/Button';
import TableAndTitleContainer from 'src/layouts/AppLayout/components/TableAndTitleContainer';
import { SelectedThemes, ThemesContainer } from './style';
import home from 'src/assets/home.svg';
import Theme from 'src/models/theme';
import ActionButtonsContainer from 'src/layouts/AppLayout/components/ActionButtonsContainer';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import makeQuestion from 'src/helpers/make-question';
import useModal from 'src/hooks/useModal';
import showErrorMessage from 'src/helpers/show-error-message';
import showSuccessMessage from 'src/helpers/show-success-message';
import SystemError from 'src/models/error';
import {
  deleteTheme as deleteThemeService,
  getThemes as getThemesService,
  toggleThemeActive as toggleThemeActiveService,
} from 'src/services/themes';
import SpecialtyCreationContext from 'src/contexts/SpecialtyCreationContext';
import clamp from 'clamp-js';
import useLoading from 'src/hooks/useLoading';
import CreateAndEditThemes from './components/CreateAndEditThemes';
import AppContext from 'src/contexts/AppContext';

interface ThemesProps {
  modalId?: string;
}

const Themes: React.FC<ThemesProps> = ({ modalId }) => {
  const history = useHistory();
  const [themes, setThemes] = useState([] as Theme[]);
  const [selectedThemes, setSelectedThemes] = useState([] as Theme[]);
  const selectedThemesContainer = useRef<HTMLSpanElement>(null);
  const { setIsLoading } = useLoading();
  const { hideModal, showModal } = useModal();
  const {
    selectedThemes: specialtySelectedThemes,
    setSelectedThemes: setSpecialtySelectedThemes,
  } = useContext(SpecialtyCreationContext);
  const { selectedPartner } = useContext(AppContext);

  const getThemes = useCallback(async () => {
    setIsLoading(true);

    const filters = {} as any;
    if (selectedPartner?.segmentation_item_id) {
      filters.segmentation_item_ids = [
        selectedPartner.segmentation_item_id ||
        process.env.REACT_APP_MANOLE_UUID ||
        '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
      ];
    }
    if (modalId) {
      filters.is_active = true;
    }

    const { data: themes } = await getThemesService(filters);
    setThemes(themes);

    setIsLoading(false);
  }, [modalId, selectedPartner?.segmentation_item_id, setIsLoading]);

  const createTheme = () => {
    if (modalId) {
      showModal(
        'Criar tema',
        <CreateAndEditThemes onUpdateOrSave={getThemes} />
      );
    } else {
      history.push(`/customization/academic/themes/create-theme`);
    }
  };

  const editTheme = useCallback(
    (themeId: string) => {
      if (modalId) {
        showModal(
          'Editar tema',
          <CreateAndEditThemes themeId={themeId} onUpdateOrSave={getThemes} />
        );
      } else {
        history.push(`/customization/academic/themes/edit-theme/${themeId}`);
      }
    },
    [history, getThemes, modalId, showModal]
  );

  const removeTheme = useCallback(
    async (themeId: string) => {
      const result = await makeQuestion('Deseja excluir este tema?');

      if (result.isConfirmed) {
        try {
          await deleteThemeService(themeId);

          showSuccessMessage('Tema removido com sucesso');

          await getThemes();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getThemes]
  );

  const toggleThemeActive = useCallback(
    async (themeId: string, isActive: boolean) => {
      const result = await makeQuestion(
        `Deseja ${isActive ? 'inativar' : 'ativar'} este tema?`
      );

      if (result.isConfirmed) {
        try {
          const shouldActive = !isActive;
          await toggleThemeActiveService(themeId, shouldActive);

          showSuccessMessage(
            `Tema ${shouldActive ? 'ativado' : 'inativado'} com sucesso`
          );

          await getThemes();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [getThemes]
  );

  const isSelectedTheme = useCallback(
    (theme: Theme) => {
      return (selectedThemes || [])
        .map((c) => c.content_id)
        .includes(theme.content_id);
    },
    [selectedThemes]
  );

  const selectTheme = useCallback(
    (theme: Theme) => {
      setSelectedThemes((themes) => [...themes, theme]);
    },
    [setSelectedThemes]
  );

  const unselectTheme = useCallback(
    (theme: Theme) => {
      setSelectedThemes((themes) =>
        themes.filter((c) => c.content_id !== theme.content_id)
      );
    },
    [setSelectedThemes]
  );

  const applySelectedThemes = () => {
    setSpecialtySelectedThemes(selectedThemes);
    hideModal(modalId);
  };

  const themesToShow = useMemo(() => {
    return (themes || []).map((theme) => ({
      ...theme,
      rowColor: !modalId
        ? 'auto'
        : isSelectedTheme(theme)
          ? 'var(--primary-40)'
          : 'transparent',
      actions: (
        <ActionButtonsContainer>
          {!modalId ? (
            <>
              <Button
                primary
                type="button"
                onClick={() => editTheme(theme.content_id)}
              >
                <AiOutlineEdit />
                <span>Editar</span>
              </Button>
              <Button
                warning
                type="button"
                onClick={() =>
                  toggleThemeActive(theme.content_id, theme.is_active)
                }
              >
                {theme.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}{' '}
                {theme.is_active ? 'Inativar' : 'Ativar'}
              </Button>
              <Button
                dangerFill
                type="button"
                onClick={() => removeTheme(theme.content_id)}
              >
                <BsTrashFill />
                <span>Excluir</span>
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                table
                onClick={() =>
                  isSelectedTheme(theme)
                    ? unselectTheme(theme)
                    : selectTheme(theme)
                }
              >
                {isSelectedTheme(theme) ? (
                  <>
                    <AiOutlineMinus />
                    <span>Remover</span>
                  </>
                ) : (
                  <>
                    <AiOutlinePlus />
                    <span>Adicionar</span>
                  </>
                )}
              </Button>
            </>
          )}
        </ActionButtonsContainer>
      ),
    }));
  }, [
    editTheme,
    isSelectedTheme,
    modalId,
    removeTheme,
    selectTheme,
    themes,
    toggleThemeActive,
    unselectTheme,
  ]);

  useEffect(() => {
    getThemes();
  }, [getThemes]);

  useEffect(() => {
    if (selectedThemesContainer.current) {
      clamp(selectedThemesContainer.current, { clamp: 1 });
    }
  }, [selectedThemesContainer]);

  useEffect(() => {
    if (specialtySelectedThemes?.length) {
      setSelectedThemes(specialtySelectedThemes);
    }
  }, [specialtySelectedThemes]);

  return (
    <ThemesContainer isOnModal={!!modalId}>
      {!modalId ? (
        <TitleAndBreadcrumbContainer>
          <PageTitle>Temas</PageTitle>
          <Breadcrumb
            crumbs={[
              <Link to="/home">
                <img src={home} alt="home" />
              </Link>,
              <strong>Personalização</strong>,
              <Link to="/customization/academic">Acadêmico</Link>,
              <span>Temas</span>,
            ]}
          />
        </TitleAndBreadcrumbContainer>
      ) : (
        <></>
      )}

      <CreateAndSearchContainer>
        <Button type="button" success onClick={createTheme}>
          Criar novo tema
        </Button>
      </CreateAndSearchContainer>

      {modalId ? (
        <SelectedThemes>
          {selectedThemes?.length ? (
            <>
              <strong>Temas selecionados:</strong>
              <span ref={selectedThemesContainer} className="selected-themes">
                {(selectedThemes || []).map((c) => c.name).join(', ')}
              </span>
            </>
          ) : (
            <span></span>
          )}
          <Button primary type="button" onClick={applySelectedThemes}>
            Aplicar seleção
          </Button>
        </SelectedThemes>
      ) : (
        <></>
      )}

      <TableAndTitleContainer>
        <PageTitle dark medium>
          Temas
        </PageTitle>
        <Table
          headerConfigs={[
            {
              label: 'Nome',
              propName: 'name',
            },
            {
              label: 'Ações',
              propName: 'actions',
              attributes: { style: { width: !modalId ? '340px' : '140px' } },
            },
          ]}
          items={themesToShow}
        />
      </TableAndTitleContainer>
    </ThemesContainer>
  );
};

export default Themes;
