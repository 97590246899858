import React from 'react';
import useLoading from 'src/hooks/useLoading';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--primary);
  width: 120px;
  height: 120px;
`;

const Text = styled.p`
  font-size: 1em;
  color: var(--white);
`;

const Loading: React.FC = () => {
  const { isLoading } = useLoading();

  return isLoading ? (
    <LoadingContainer>
      <Spinner></Spinner>
      <Text>Carregando...</Text>
    </LoadingContainer>
  ) : (
    <></>
  );
};

export default Loading;
