import styled from 'styled-components';

const TableContainer = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-transform: uppercase;
      background: var(--table-header-bg);
      border-top: solid 1px var(--table-header-border);
      border-bottom: solid 1px var(--table-header-border);
      color: var(--gray);
      font-weight: 600;
      padding: 8px 4px;

      :not(:first-child):not(:last-child) {
        border-left: none;
        border-right: none;
      }

      :first-child {
        border-left: solid 1px var(--table-header-border);
      }

      :last-child {
        border-right: solid 1px var(--table-header-border);
      }
    }

    td {
      color: var(--text-gray);
      padding: 4px;
      border-bottom: solid 1px var(--border-gray);

      :last-child > .table-data-content {
          overflow: visible !important;
        }
      }
    }

    th,
    td {
      text-align: left;
      font-size: 0.8rem;

      :first-child {
        padding-left: 16px;
      }

      :last-child {
        padding-right: 16px;
      }
    }
  }
`;

export { TableContainer };
