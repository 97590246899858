const cpf = (value: string) => {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  } else {
    return '';
  }
};

const cep = (value: string) => {
  if (value) {
    const numbers = value.replace(/[^\d]/g, '');
    const len = numbers.length;

    if (len < 6) {
      return numbers;
    } else if (len > 8) {
      return value.slice(0, 9);
    }

    const formattedValue = `${numbers.slice(0, 5)}-${numbers.slice(5)}`;

    return formattedValue
  } else {
    return '';
  }
};

const phone = (value: string) => {
  if (value) {
    const numbers = value.replace(/[^\d]/g, '');
    const len = numbers.length;
    let formattedValue = '';

    if (len < 3) {
      return numbers;
    } else if (len > 12) {
      return value.slice(0, 16);
    }

    if (len === 12) {
      formattedValue = `(${numbers.slice(0, 3)}) ${numbers.slice(
        3,
        8
      )}-${numbers.slice(8)}`;
    } else if (len === 11) {
      formattedValue = `(${numbers.slice(0, 2)}) ${numbers.slice(
        2,
        7
      )}-${numbers.slice(7)}`;
    } else if (len > 6) {
      formattedValue = `(${numbers.slice(0, 2)}) ${numbers.slice(
        2,
        6
      )}-${numbers.slice(6)}`;
    } else {
      formattedValue = `(${numbers.slice(0, 2)}) ${numbers.slice(2, len)}`;
    }

    return formattedValue
  } else {
    return '';
  }
};

const number = (value: string) => {
  if (value) {
    const numbers = value.replace(/[^\d]/g, '');
    return numbers
  } else {
    return '';
  }
};

export const Mask = {
  cpf,
  cep,
  phone,
  number,
}
