import FeatureList from 'src/layouts/AppLayout/components/FeatureList';
import styled from 'styled-components';

const ReportsContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const ReportsFeatureList = styled(FeatureList)``;

export { ReportsContainer, ReportsFeatureList };
