import { v4 as uuidv4 } from 'uuid'
import httpClient from 'src/http-client'
import User, { IUser, IUserMe, IUserPost, IUserPostResponse, IUserPut } from 'src/models/user'
import { Mask } from 'src/helpers/Mask'

interface IGetUsers {
  partner_id?: string
}

export const getUsers = async (params?: IGetUsers) => {
  const partner_id = params?.partner_id
  let users: User[] = []

  if (!partner_id) {
    const { data } = await httpClient.get<User[]>('/users')
    users = data
  }
  else {
    const { data } = await httpClient.get<{ users: User[] }>(`/partners/users/${partner_id}`)
    users = data.users
  }

  const usersSort = users.sort((u1, u2) => {
    const u1CreationDate = u1.created_at
      ? new Date(u1.created_at)
      : new Date()
    const u2CreationDate = u2.created_at
      ? new Date(u2.created_at)
      : new Date()

    return u1CreationDate > u2CreationDate ? 1 : -1
  })

  return usersSort
}

export const getUserMeService = async (user_id?: string): Promise<IUser> => {
  const { data } = await httpClient.get<IUserMe>(`/users/me`)
  return data.user
}

export const getUserService = async (user_id?: string): Promise<IUser | undefined> => {
  const { data } = await httpClient.get<IUser[]>(`/users`, {
    params: {
      user_id,
    }
  })

  return data[0]
}

export const signUpService = async (user: IUserPost) => {
  const credentials = {
    ...user,
    cpf: Mask.number(user.cpf),
    user_name: `${user.name?.split(' ')[0]}_${uuidv4()}`,
    partner_id: process.env.REACT_APP_MANOLE_UUID || '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
  }

  const { data } = await httpClient.post<IUserPostResponse>(`/sessions/signup`, credentials)

  return data
}

export const toggleUserAdmin = async (userId: string) => {
  const { data } = await httpClient.patch(`/users/${userId}/admin`)
  return data
}

export const updateUserService = async (userId: string, user: IUserPut) => {
  const { data } = await httpClient.put<IUser>(`/users/${userId}`, user)
  return data
}

export const deleteUserService = async (user_id: string) => {
  await httpClient.delete('users', {
    params: {
      user_id,
    }
  })
}
